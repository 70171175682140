import { Alert, CircularProgress } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { USER_FIND_BY_ID } from "~/Queries/Users/User.query";

import ProfileForm from "~/Components/Profile/ProfileForm";


const ProfileEditPage = () => {

    const { id } = useParams();

    const { data, loading, error } = useQuery(USER_FIND_BY_ID, {
        variables: { user_id: id }
    })


    if (error) {
        return <Alert severity="error">{error.message ? error.message : 'Oops! An error has ocurred...Try again later!'}</Alert>

    }

    return (
        loading ? <CircularProgress /> : <ProfileForm data={data.userFindById} />
    )
}

export default ProfileEditPage;