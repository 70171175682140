import React, { useCallback, useState } from "react";
import { Box, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Select, TextField, TextFieldProps } from "@mui/material";
import { Moment } from "moment";
import moment from 'moment-timezone';






import Form from "~/Components/Form";
import { AVAILABLE_DATE_ADD, AVAILABLE_DATE_UPDATE } from "~/Queries/AvailableDate/AvailableDate.query";
import AvailableDatePicker from "./AvailableDatePicker";
import TimePicker from '@mui/lab/TimePicker';
import DaysOfWeekInput from "./DaysOfWeekInput";
import AvailableSwitch from "./AvailableSwitch";



export enum SessionType {
    ON_STREAM = 'ON_STREAM',
    OFF_STREAM = 'OFF_STREAM',
    ALL = 'ALL'
}

export enum DayOfWeek {
    SUNDAY = 'SUNDAY',
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY'

}

console.log(DayOfWeek.MONDAY);

class AvailableDateInputModel {
    available!: Boolean;
    session_type!: SessionType;
    start_date!: Date;
    end_date?: Date | null;
    start_hour!: Number;
    start_minute!: Number;
    end_hour!: Number;
    end_minute!: Number;
    timezone!: string;
    days!: DayOfWeek[];
}

const defaultData: AvailableDateInputModel = {
    session_type: SessionType.ALL,
    start_date: new Date(),
    available: true,
    end_date: null,
    start_hour: 8,
    end_hour: 12,
    start_minute: 0,
    end_minute: 0,
    days: [DayOfWeek.MONDAY, DayOfWeek.TUESDAY],
    timezone: moment.tz.guess()


}


interface AvailableDateFormInterface {
    id?: string | null;
    data?: any,
}



export default function AvailableDateForm(props: AvailableDateFormInterface) {
    const [available, setAvaialble] = useState<Boolean>(props.data ? props.data.available : false);
    const onAvialableChange = useCallback((e, setFieldValue) => {
        setAvaialble(e.target.checked);
        setFieldValue('available', e.target.checked);

    }, [available, props.data])

    const [endDateForever, setEndDateForever] = useState<Boolean>(true);
    return (
        <Form
            addTitle={available ? "Add/Block Dates" : "Add/Block Dates"}
            editTitle={available ? "Edit/Block Dates" : "Edit/Block Dates"}
            model={AvailableDateInputModel}
            createMutation={AVAILABLE_DATE_ADD}
            updateMutation={AVAILABLE_DATE_UPDATE}
            backLink="/sessions/available-dates"
            id={props.id}
            data={props.data || defaultData}

        >
            {({ errors, handleBlur, setFieldValue, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <Grid container spacing={2}>
                    {/*  Available / Blocked  */}
                    <Grid item md={12} xs={12} >
                        <FormControlLabel
                            control={<AvailableSwitch sx={{ m: 1 }} defaultChecked />}
                            name="available"
                            onBlur={handleBlur}
                            onChange={(e) => onAvialableChange(e, setFieldValue)}
                            label={values.available ? 'Available' : 'Blocked'}
                        />
                    </Grid>
                    {/* Available Date Picker */}
                    <AvailableDatePicker startDate={values.start_date} endDate={values.end_date}
                        onStartDateChange={function (d: Moment | null): void {
                            setFieldValue('start_date', d);
                        }} onEndDateChange={function (d: Moment | null): void {
                            setFieldValue('end_date', d);
                        }}
                    />
                    {/* From */}
                    <Grid item md={'auto'} xs={12} >
                        <FormControl variant="standard" margin="dense">
                            <TimePicker
                                minutesStep={15}
                                label="From"
                                value={moment().hours(values.start_hour).minutes(values.start_minute)}
                                onChange={(d: Moment | null) => {
                                    if (d) {
                                        setFieldValue('start_hour', d.hours());
                                        setFieldValue('start_minute', d.minutes());
                                    }
                                }
                                }
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                    </Grid>
                    {/* To */}
                    <Grid item md={'auto'} xs={12} >
                        <FormControl variant="standard" margin="dense">

                            <TimePicker
                                label="To"
                                value={moment().hours(values.end_hour).minutes(values.end_minute)}
                                minutesStep={15}
                                onChange={(d: Moment | null) => {
                                    if (d) {
                                        setFieldValue('end_hour', d.hours());
                                        setFieldValue('end_minute', d.minutes());
                                    }
                                }
                                }
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                    </Grid>
                    {/* Session Type */}
                    <Grid item xs={12} >
                        <Grid md={5} xs={12} >
                            <FormControl fullWidth margin="dense">
                                <InputLabel color="primary" sx={{ fontSize: 18, fontWeight: 'bold', color: "#FF11A3" }}>Session Type</InputLabel>
                                <Select
                                    label="Session Type"
                                    name="session_type"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.session_type}
                                >
                                    <MenuItem value={SessionType.ALL}>Both</MenuItem>
                                    <MenuItem value={SessionType.ON_STREAM}>On-Stream</MenuItem>
                                    <MenuItem value={SessionType.OFF_STREAM}>Off-Stream</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {/* Days of week */}
                    <DaysOfWeekInput selected={values.days} onChange={(days) => setFieldValue('days', days)}></DaysOfWeekInput>
                </Grid>

            )}
        </Form >
    )
}