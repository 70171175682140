export default function componentStyleOverrides(theme: any) {
    const bgColor = theme.colors?.grey50;
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: '4px'
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '& .MuiDrawer-paper': {
                        background: theme.sidebarGradient,

                    },
                    '& .MuiList-root': {
                        '& .MuiListItemButton-root': {
                            '& .MuiListItemIcon-root': {
                                color: theme.secondaryLight,
                            },
                            '& .MuiListItemText-root': {
                                '& .MuiTypography-root': {
                                    color: theme.secondaryLight,
                                }
                            }
                        }
                    },
                    '& .MuiListItemButton-root': {
                        color: theme.darkTextPrimary,
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        '&.Mui-selected': {
                            color: theme.menuSelected,
                            background: theme.menuSelectedBackground,
                            '&:hover': {
                                background: theme.menuSelectedBackground,
                            },
                            '& .MuiSvgIcon-root': {
                                color: theme.menuSelected,
                            },
                            '& .MuiListItemIcon-root': {
                                color: theme.menuSelected
                            },
                            '& .MuiListItemText-root': {
                                '& .MuiTypography-root': {
                                    color: theme.menuSelected,
                                    fontWeight: 'bold'
                                }
                            }

                        },
                        '&:hover': {
                            background: theme.menuSelectedBackground,
                            color: theme.menuSelected,
                            '& .MuiListItemIcon-root': {
                                color: theme.menuSelected,
                            },
                            '& .MuiSvgIcon-root': {
                                color: theme.menuSelected,
                            },
                            '& .MuiListItemText-root': {
                                color: '#fff',
                                '& .MuiTypography-root': {
                                    color: theme.menuSelected + ' !important',
                                }
                            }

                        }
                    },
                }
            }
        },


        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    //minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    // marginTop: 20
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: bgColor,
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.colors?.grey400
                    },
                    '&:hover $notchedOutline': {
                        borderColor: theme.colors?.primaryLight
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    }
                },
                input: {
                    fontWeight: 500,
                    background: bgColor,
                    padding: '15.5px 14px',
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryDark,
                    background: theme.colors?.primary200
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700
                }
            }
        }
    };
}