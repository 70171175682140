import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { CircularProgress, Avatar, Fab, Box } from '@mui/material';


import { DataGrid, GridToolbar, GridActionsCellItem, GridColumns } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';



import { HOME_CAROUSEL_ITEM_DELETE, HOME_CAROUSEL_ITEM_FIND_BY_ID, HOME_CAROUSEL_ITEM_LIST } from '~/Queries/HomeCarouselItems/HomeCarouselItems.query';


const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1000
};








export default function HomeCarouselItemPage() {
    const { loading, error, data, refetch } = useQuery(HOME_CAROUSEL_ITEM_LIST, {
        fetchPolicy: 'cache-and-network'
    });

    const client = useApolloClient();

    const navigate = useNavigate();

    const deleteItem = async (id: string) => {
        const response = await client.mutate(
            {
                mutation: HOME_CAROUSEL_ITEM_DELETE,
                variables: { id }
            }
        )

        if (response.data.homeCarouselItemDelete) {
            refetch();
        }

    };


    const editItem = async (id: string) => {
        navigate('/home-carousel-items/edit/' + id )

    };


    const headCells: GridColumns = [
        { field: '_id', headerName: 'Id', hide: true },
        {
            field: 'image', headerName: 'Image', renderCell: (params) => {
                if (!params.row.images) {
                    return <Avatar alt="Image" >NI</Avatar>
                }
                return <Avatar src={params.row.images[0].sm} alt="Image" />
            }
        },
        { field: 'title', headerName: 'Title' },

        { field: 'created_at', headerName: 'Created', width: 150 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            getActions: ({ id, getValue }) => {
                const _id = getValue(id, '_id') as string;
                return [
                    <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={() => editItem(_id)}  />,
                    <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => deleteItem(_id)} />,
                ];
            },
        }
    ];




    if (loading) {
        return <CircularProgress />
    }

    return (
        <Box>

            <div style={{ height: '70vh', width: '100%' }}>

                <DataGrid
                    getRowId={(row) => row._id}
                    rows={data.homeCarouselItemList}
                    columns={
                        headCells
                    }
                    components={{
                        Toolbar: GridToolbar,
                    }}
                ></DataGrid>

            </div>

            <Fab color="primary" aria-label="add" sx={fabStyle} onClick={() => navigate('/home-carousel-items/add')}>
                <AddIcon />
            </Fab>
        </Box>
    );
}


