import { ListItem, Grid, ListItemText } from "@mui/material"
import moment from "moment"

interface SessionChatMessagesProps {
    items: any
}

export default function SessionChatMessages(props: SessionChatMessagesProps) {
    return props.items.map((item: { _id: string, is_my_message: Boolean, message: string, created_at: Date }) => (
        <ListItem key={item._id}>
            <Grid container>
                <Grid item xs={12}>
                    <ListItemText primary={item.message} sx={{ textAlign: item.is_my_message ? "right" : "left" }}></ListItemText>
                </Grid>
                <Grid item xs={12}>
                    <ListItemText secondary={moment(item.created_at).format("YYYY-MM-DD hh:mm")} sx={{ textAlign: item.is_my_message ? "right" : "left" }}></ListItemText>
                </Grid>
            </Grid>
        </ListItem>
    ))


}