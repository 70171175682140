import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';


import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';

import NotificationsIcon from '@mui/icons-material/Notifications';

import Sidebar, { sidebarWidth } from '~/Components/Sidebar';
import { Toolbar } from '@mui/material';


function Copyright(props: any) {
    return (
        <Typography variant="body2" color="#fff" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://owrlee.com/">
            Owrlee
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}




interface LoginLayoutProps {
    children?: React.ReactElement | React.ReactElement[];
    title?: String;
}

export default function LoginLayout({ children, title }: LoginLayoutProps) {
    console.log(children);
    return (

        <Box sx={{ display: 'flex'}} >
            <CssBaseline />
            <Box
                component="main"
                sx={{
                    background: 'url("/assets/img/login-image.svg") rgb(24, 31, 36)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 15%',
                    backgroundSize: '125vw',
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper sx={{ p: 12, display: 'flex', flexDirection: 'column', borderRadius:10 }}>
                                {children}
                            </Paper>
                        </Grid>
                    </Grid>



                </Container>

            </Box>
            <footer style={{ bottom: 0, left: 0, right: 0, position: 'absolute',textAlign: 'center' }}>
                <Copyright sx={{ pt: 4 }} />

            </footer>
        </Box>
    );
}