import { useCallback, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import colors from "~/assets/scss/variables.scss";

// material-ui
import { useTheme } from "@mui/material/styles";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import {
  USER_DATA,
  USER_LOGIN,
  USER_RECOVER,
  USER_VERIFY_VERIFICATION_CODE,
} from "~/Queries/Users/User.query";
import { authUserAtom } from "~/Recoil/Auth.recoil";
import AuthUtility from "~/Utilities/Auth/Auth.utility";
import { User } from "~/Models/User.model";
import Logo from "~/Components/Logo";
import { useSnackbar } from "notistack";

const StyledLogo = styled(Logo)(
  ({ theme }) => `

        flex: 1;

        margin: 20px;
        width: 300px;
        path {
            transition: all 0.2s ease-in-out;
            fill: #000;

        }
        :hover {
            path {
                fill: #000;
            }
        }
    `
);

const EmailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// ============================|| FIREBASE - LOGIN ||============================ //

const ForgotPasswordPage = ({ ...others }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string | null>(null);
  const [verificationCodeSent, setVerificationCodeSent] =
    useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const client = useApolloClient();

  const onEmailChange = useCallback((event) => {
    if (EmailRegex.test(event.target.value)) {
      setEmail(event.target.value);
    } else {
      setEmail(null);
    }
  }, []);

  const onVerificationCodeChange = useCallback((event) => {
    setVerificationCode(event.target.value);
  }, []);

  const onRequest = useCallback(async () => {
    try {
      const userResponse = await client.mutate({
        mutation: USER_RECOVER,
        variables: { email },
        // errorPolicy: 'all'
      });
      const user = userResponse.data.gamerRecoverPassword;

      setVerificationCodeSent(
        userResponse.data.gamerRecoverPassword._id !== null
      );
      setUserId(user._id);
    } catch (e) {
      enqueueSnackbar("Invalid User. Please re-enter the your email", {
        variant: "error",
      });
    }
  }, [client, email, enqueueSnackbar]);

  const setVerificationCodeSubmit = useCallback(async () => {
    try {
      const userResponse = await client.mutate({
        mutation: USER_VERIFY_VERIFICATION_CODE,
        variables: { user_id: userId, verification_code: verificationCode },
        // errorPolicy: 'all'
      });
      const isValid = userResponse.data.userVerifyVerificationCode;

      if (isValid) {
        enqueueSnackbar("Password sent.", {
          variant: "success",
        });
        navigate("/");
      } else {
        enqueueSnackbar(
          "Please re-enter verification code or contact us for additional assistance.",
          {
            variant: "error",
          }
        );
      }
    } catch (e) {
      enqueueSnackbar(
        "Please re-enter verification code or contact us for additional assistance.",
        {
          variant: "error",
        }
      );
    }

    return false;
  }, [client, enqueueSnackbar, navigate, userId, verificationCode]);

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ mb: 2 }}>
            <StyledLogo />
            <Typography variant="h1">Recover</Typography>
          </Box>
        </Grid>
      </Grid>

      <FormControl sx={{ m: 1 }} variant="outlined">
        <TextField
          label="1. Request Verification Code"
          placeholder="Email"
          //value={values.weight}
          disabled={verificationCodeSent}
          onChange={onEmailChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!email || verificationCodeSent}
                  onClick={onRequest}
                >
                  Request
                </Button>
              </InputAdornment>
            ),
          }}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
        />
      </FormControl>

      <FormControl sx={{ m: 1 }} variant="outlined">
        <TextField
          label="2 - Enter Verification Code"
          placeholder="Verification code"
          type={"number"}
          //value={values.weight}
          onChange={onVerificationCodeChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!verificationCodeSent}
                  onClick={setVerificationCodeSubmit}
                >
                  Verify
                </Button>
              </InputAdornment>
            ),
          }}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
        />
      </FormControl>

      <Link href="/" mt={3} sx={{ textAlign: "center" }}>
        Go back to Login
      </Link>
    </>
  );
};

export default ForgotPasswordPage;
