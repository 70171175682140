import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

import AvailableDateForm from "~/Components/AvailableDateForm";
import { AVAILABLE_DATE_FIND_BY_ID } from "~/Queries/AvailableDate/AvailableDate.query";


export default () => {
    const { id } = useParams();

    const { data, loading, error } = useQuery(AVAILABLE_DATE_FIND_BY_ID, {
        variables: { id }
    })
    console.log(loading ? {} : data.availableDateFindById);
    return (
        loading ? <CircularProgress /> : <AvailableDateForm id={id} data={data.availableDateFindById} />
    )
}