import gql from 'graphql-tag';

export const SESSION_MESSAGE_FIELDS = gql`
  fragment SessionMessageFields on SessionMessage {
    _id
    user_id
    message
    is_my_message
    created_at
  }
`;
