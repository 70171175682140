import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { DayOfWeek } from "./AvailableDateForm";

interface DaysOfWeekInputProps {
    selected: DayOfWeek[],
    onChange: (days: DayOfWeek[]) => void,
}

export default function DaysOfWeekInput(props: DaysOfWeekInputProps) {
    const [selectedDays,setSelectedDays] = useState<DayOfWeek[]>(props.selected);
    const capitalizeFirstLetter = useCallback(
        (dayName: string): string => {
            const ret = dayName.charAt(0).toUpperCase() + dayName.toLowerCase().slice(1);
            return ret;
        }, [])
    const handleChange = useCallback((element: React.ChangeEvent<HTMLInputElement>, checked: Boolean) => {

        let days: DayOfWeek[] = props.selected;
        const value: DayOfWeek = element.target.value as DayOfWeek;
        const index = props.selected.indexOf(value);


        console.log({ days, checked, index, element, value })

        if (!checked && index > -1) {
            days = days.filter((item) => item != value
            );

        } else {
            if (index < 0) {
                days = [...days, value];
            }
        }
        console.log({ days, checked, index, element, value })

        props.onChange(days);
        setSelectedDays(days);
    }, [selectedDays]);

    const daysItems = Object.keys(DayOfWeek).map((dayStringValue: string) => {
        console.log(dayStringValue)


        const dayName = capitalizeFirstLetter(dayStringValue);



        return (
            <FormControlLabel
                key={dayName}
                control={
                    <Checkbox checked={selectedDays.indexOf(dayStringValue as DayOfWeek) > -1} onChange={handleChange} name="days" value={dayStringValue} />
                }
                label={dayName}
            />)
    })


    return (
        <FormControl fullWidth sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">Days</FormLabel>
            <FormGroup>
                {daysItems}
            </FormGroup>

        </FormControl>
    )
}