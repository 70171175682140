import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import colors from "~/assets/scss/variables.scss";

// material-ui
import { useTheme } from "@mui/material/styles";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { USER_DATA, USER_LOGIN } from "~/Queries/Users/User.query";
import { authUserAtom } from "~/Recoil/Auth.recoil";
import AuthUtility from "~/Utilities/Auth/Auth.utility";
import { User } from "~/Models/User.model";
import Logo from "~/Components/Logo";

const StyledLogo = styled(Logo)(
  ({ theme }) => `

        flex: 1;

        margin: 20px;
        width: 300px;
        path {
            transition: all 0.2s ease-in-out;
            fill: #000;

        }
        :hover {
            path {
                fill: #000;
            }
        }
    `
);

// ============================|| FIREBASE - LOGIN ||============================ //

const Login = ({ ...others }) => {
  const theme = useTheme();

  const client = useApolloClient();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(true);

  const [authUser, setAuthUser] = useRecoilState(authUserAtom);

  const googleHandler = async () => {
    console.error("Login");
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ mb: 2 }}>
            <StyledLogo />
            <Typography variant="subtitle1">Welcome to Owrlee Gamer Portal!</Typography>
          </Box>
        </Grid>
      </Grid>

      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            console.log({ values });

            // console.log('userInput', userInput);
            const userResponse = await client.mutate({
              mutation: USER_LOGIN,
              variables: { email: values.email, password: values.password },
              // errorPolicy: 'all'
            });
            const token = userResponse.data.userLogin;

            await AuthUtility.setToken(token);

            const userDataResponse = await client.query({
              query: USER_DATA,
            });
            const userAuth = userDataResponse.data.userData as User;
            if (userAuth._id && userAuth.type.includes("gamer")) {
              setAuthUser(userAuth);
              navigate("/");
            } else {
              await AuthUtility.removeToken();
              setAuthUser(null);
              setStatus({ success: false });
              setSubmitting(false);
              return;
            }

            setStatus({ success: true });
            setSubmitting(false);
          } catch (err: any) {
            console.error(err);

            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <FormControl
              fullWidth
              error={Boolean(touched.email && errors.email)}
              margin="dense"
            >
              <InputLabel htmlFor="outlined-adornment-email-login">
                Email Address / Username
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-login"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Email Address / Username"
                inputProps={{}}
              />
              {touched.email && errors.email && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-email-login"
                >
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.password && errors.password)}
              margin="normal"
            >
              <InputLabel htmlFor="outlined-adornment-password-login">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? "text" : "password"}
                value={values.password}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                inputProps={{}}
              />
              {touched.password && errors.password && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-password-login"
                >
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(event) => setChecked(event.target.checked)}
                    name="checked"
                    color="primary"
                  />
                }
                label="Remember me"
              />
            </Stack>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <Button
                disableElevation
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                Sign in
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        mt={4}
      >
        <Link href="/forgot-password">Forgot Password?</Link>
      </Stack>
    </>
  );
};

export default Login;
