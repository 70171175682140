import React from 'react';
import { useApolloClient } from '@apollo/client';
import { Fab, Box, FormControl, FormHelperText, TextField, Button, Chip, Avatar, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';


import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { HOME_CAROUSEL_ITEM_ADD, HOME_CAROUSEL_ITEM_UPDATE } from '~/Queries/HomeCarouselItems/HomeCarouselItems.query';
import { HomeCarouselItem } from '~/Models/HomeCarouseltem.model';





const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1000
};

const Input = styled('input')({
    display: 'none',
});

interface State {
    title: string;
    subtitle: string;
    file: File | null;
    previewFileData?: string | null;
    button_text?: string;
    subtitle_color?: string;
    is_internal?: boolean;
    link?: string;
    submit: any;

}

interface HomeCarouselItemFormInterface {
    id?: string | null;
    data?: HomeCarouselItem
}



export default function HomeCarouselItemForm({ id, data = {
    _id: "",
    title: "",
    subtitle: "",
    image: "",
    active: true,
    images: [],
    created_at: new Date,
    updated_at: new Date
} }: HomeCarouselItemFormInterface) {
    const navigate = useNavigate();
    const [previewFileData, setPreviewFileData] = React.useState<string>(data.images && data.images.length > 0 ? data.images[0].md as string : "");



    const validationSchema = Yup.object().shape({
        title: Yup.string().max(255).required('Title is empty'),
        subtitle: Yup.string().max(255).required('Subtitle is empty')
    })

    const client = useApolloClient();



    const onSubmit = async (values: any, { setErrors, setStatus, setSubmitting }: any) => {
        try {
            let saved = false;
            if (id) {
                const response = await client.mutate({
                    mutation: HOME_CAROUSEL_ITEM_UPDATE,
                    variables: {
                        id,
                        data: {
                            title: values.title,
                            subtitle: values.subtitle,
                            button_text: values.button_text,
                            subtitle_color: values.subtitle_color,
                            link: values.link,
                            is_internal: values.is_internal,
                            file: values.file
                        }
                    }
                });
                saved = response.data.homeCarouselItemUpdate;
            } else {
                const response = await client.mutate({
                    mutation: HOME_CAROUSEL_ITEM_ADD,
                    variables: {
                        data: {
                            title: values.title,
                            subtitle: values.subtitle,
                            button_text: values.button_text,
                            subtitle_color: values.subtitle_color,
                            link: values.link,
                            is_internal: values.is_internal,
                            file: values.file
                        }
                    }
                });
                saved = response.data.homeCarouselItemAdd;
            }
            if (saved) {
                await setStatus({ success: true });
                await setSubmitting(false);
                navigate('/home-carousel-items/', { state: { refresh: true } })
            } else {
                setStatus({ success: false });
                setErrors({ submit: 'Error submiting the item. Please try again.' });
                setSubmitting(false);
            }
        }
        catch (err: any) {

            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);

        }


        setStatus({ success: false });
        setSubmitting(false);

    }

    const handleUpload =
        (prop: keyof State, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {

            if (event.target.files) {
                const reader = new FileReader();
                const file = event.target.files[0];
                reader.readAsDataURL(file); // read file as data url
                reader.onload = (fileEvent: ProgressEvent<FileReader>) => { // called once readAsDataURL is completed
                    if (fileEvent.loaded && fileEvent.target) {
                        setPreviewFileData(fileEvent.target.result as string);
                    }

                }
                setFieldValue('file', file);
            }

        };



    return (
        <Box>


            <Formik
                initialValues={data}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ errors, handleBlur, setFieldValue, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Grid item md={2} xs={12}>
                                <label htmlFor="contained-button-file">
                                    <Input name="file" accept="image/*" id="contained-button-file" multiple type="file" onBlur={handleBlur}
                                        onChange={handleUpload('file', setFieldValue)} />
                                    <Button variant="contained" component="span" color="secondary">
                                        Select image
                                    </Button>
                                </label>
                            </Grid>
                            {previewFileData && (
                                <>
                                    <Grid item md={2} xs={12}>
                                        {previewFileData && <Avatar src={previewFileData} variant="square" />}
                                    </Grid>
                                    <Grid item md={8} xs={12}>
                                        {values.file && <Chip sx={{ ml: 2 }} label={values.file?.name} variant="filled" />}
                                    </Grid>
                                </>
                            )}
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">

                                <TextField
                                    label="Title"
                                    id="outlined-start-adornment"
                                    name="title"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.title}
                                />
                            </FormControl>
                            {touched.title && errors.title && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.title}
                                </FormHelperText>
                            )}
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">

                                <TextField
                                    label="Subtitle"
                                    name="subtitle"
                                    value={values.subtitle}
                                    id="outlined-start-adornment"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {touched.subtitle && errors.subtitle && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                        {errors.subtitle}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">

                                <TextField
                                    label="Subtitle color"
                                    name="subtitle_color"
                                    value={values.subtitle_color}
                                    id="outlined-start-adornment"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">

                                <TextField
                                    label="Button Text"
                                    name="button_text"
                                    value={values.button_text}
                                    id="outlined-start-adornment"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </FormControl>

                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                <FormControlLabel control={
                                    <Checkbox
                                        name="is_internal"
                                        id="outlined-start-adornment"
                                        value={values.is_internal}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                }
                                    label="Is Internal app link?" />
                            </FormControl>

                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">

                                <TextField
                                    label="Link"
                                    name="link"
                                    value={values.link}
                                    id="outlined-start-adornment"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </FormControl>




                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Submit
                            </Button>
                        </Box>
                        <Fab color="primary" aria-label="add" sx={fabStyle} onClick={() => navigate('/home-carousel-items')}>
                            <NavigateBeforeIcon />
                        </Fab>

                    </form>
                )
                }
            </Formik >
        </Box >
    );
}
