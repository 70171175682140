import { createTheme } from '@mui/material/styles';

// assets
import colors from '../assets/scss/variables.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

 const theme = (customization: any) => {
    const color = colors;

    const themeOption = {
        ...colors,
        colors: color,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: color.primaryLight,
        darkTextPrimary: color.grey700,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        menuSelected: color.primaryMain,
        menuSelectedBackground: color.primaryLight,
        menuSelectedBack: color.primaryMain,
        divider: color.grey200,
        customization
    };

    const themeOptions = {
        palette: themePalette(themeOption),
        typography: themeTypography(themeOption)

    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;