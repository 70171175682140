import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';


import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import LogoutIcon from '@mui/icons-material/Logout';
import BugReportIcon from '@mui/icons-material/BugReport';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LayersIcon from '@mui/icons-material/Layers';
import Gamepad from '@mui/icons-material/Gamepad';
import PayoutRequestIcon from '@mui/icons-material/PaymentRounded';
import SquareFoot from '@mui/icons-material/SquareFoot';
import { StarBorder } from '@mui/icons-material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useMediaQuery, useTheme } from '@mui/material';



type RouterLinkProps = React.PropsWithChildren<{
    to: string,
    text: string,
    icon: React.ReactElement,
    setOpen: (b: React.SetStateAction<boolean>) => void;
    padding?: boolean
    end?: boolean
}>
const RouterLink = (props: RouterLinkProps) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    type MyNavLinkProps = Omit<NavLinkProps, 'to'>;
    const MyNavLink = React.useMemo(() => React.forwardRef<HTMLAnchorElement, MyNavLinkProps>((navLinkProps, ref) => {
        const { className: previousClasses, ...rest } = navLinkProps;
        const elementClasses = previousClasses?.toString() ?? "";
        return (<NavLink
            {...rest}
            ref={ref}
            to={props.to}
            end={props.end}
            className={({ isActive }) => (isActive ? elementClasses + " Mui-selected" : elementClasses)}
        />)
    }), [props.to]);
    return (
        <ListItemButton
            onClick={() => matches ? props.setOpen(false) : null}
            component={MyNavLink}
            sx={props.padding ? { pl: 4 } : {}}
        >
            <ListItemIcon >
                {props.icon}
            </ListItemIcon>
            <ListItemText primary={props.text} />
        </ListItemButton>
    )
}



interface CollapsedLinkProps {
    text: string;
    children: React.ReactElement[],
    icon: React.ReactElement
}
const CollapsedLink = (props: CollapsedLinkProps) => {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <><ListItemButton onClick={handleClick}>
            <ListItemIcon >

                {props.icon}
            </ListItemIcon>
            <ListItemText primary={props.text} />
            {open ? <ExpandLess sx={{ color: '#fff' }} /> : <ExpandMore sx={{ color: '#fff' }} />}
        </ListItemButton><Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {props.children}
                </List>
            </Collapse></>
    )
}

interface SideBarItemsProps {
    setOpen: (b: React.SetStateAction<boolean>) => void;
}
export const SideBarItems = (props: SideBarItemsProps) => (
    <React.Fragment>

        <RouterLink to="/" text="Dashboard" icon={<DashboardIcon />} setOpen={props.setOpen} />
        <CollapsedLink icon={<CalendarTodayIcon />} text="Sessions">
            <RouterLink to="/sessions/calendar" text="My Calendar" icon={<ChevronRightIcon />} padding end setOpen={props.setOpen} />
            <RouterLink to="/sessions/available-dates" text="Create/Edit Calendar" icon={<ChevronRightIcon />} padding end setOpen={props.setOpen} />
        </CollapsedLink>
        <RouterLink to="/games" text="Add/Remove Games" icon={<Gamepad />} setOpen={props.setOpen} />
        <RouterLink to="/payout-requests" text="Payouts" icon={<PayoutRequestIcon />} setOpen={props.setOpen} />
        <RouterLink to="/profile" text="Profile" icon={<PeopleIcon />} setOpen={props.setOpen} />


        <RouterLink to="/logout" text="Logout" icon={<LogoutIcon />} setOpen={props.setOpen} />
    </React.Fragment>
);
