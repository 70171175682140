import gql from 'graphql-tag';

import { HOME_CAROUSEL_ITEM_FIELDS } from './HomeCarouselItems.fragment';
//#region Queries


export const HOME_CAROUSEL_ITEM_LIST = gql`
  ${HOME_CAROUSEL_ITEM_FIELDS}
  query homeCarouselItemList {
    homeCarouselItemList {
      ...HomeCarouselItemFields
    }
  }
`;

export const HOME_CAROUSEL_ITEM_FIND_BY_ID = gql`
  ${HOME_CAROUSEL_ITEM_FIELDS}
  query homeCarouselItemFindById($id: String!) {
    homeCarouselItemFindById(id: $id) {
      ...HomeCarouselItemFields
    }
  }
`;

//#endregion Queries

//#region Mutations


export const HOME_CAROUSEL_ITEM_ADD = gql`
  ${HOME_CAROUSEL_ITEM_FIELDS}
  mutation homeCarouselItemAdd($data: HomeCarouselItemInput!) {
    homeCarouselItemAdd(data: $data) {
      ...HomeCarouselItemFields
    }
  }
`;

export const HOME_CAROUSEL_ITEM_UPDATE = gql`
  ${HOME_CAROUSEL_ITEM_FIELDS}
  mutation homeCarouselItemUpdate($id: String! ,$data: HomeCarouselItemInput!) {
    homeCarouselItemUpdate(id: $id, data: $data) {
      ...HomeCarouselItemFields
    }
  }
`;

export const HOME_CAROUSEL_ITEM_DELETE = gql`
  ${HOME_CAROUSEL_ITEM_FIELDS}
  mutation homeCarouselItemDelete($id: String!) {
    homeCarouselItemDelete(id: $id) {
      ...HomeCarouselItemFields
    }
  }
`;

//#endregion Mutations
