import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { SESSION_FIND_TODAY } from "~/Queries/Sessions/Session.query";
import { useNavigate } from "react-router-dom";

// Generate Order Data
// function createData(id: number, date: string, name: string, game: string) {
//   return { id, date, name, game };
// }

// const rows = [
//   createData(0, "16 Mar, 2019", "Elvis Presley", "Spiderman / Xbox"),
//   createData(1, "16 Mar, 2019", "Paul McCartney", "Spiderman / Xbox"),
//   createData(3, "16 Mar, 2019", "Michael Jackson", "Spiderman / Xbox"),
//   createData(4, "15 Mar, 2019", "Bruce Springsteen", "Spiderman / Xbox"),
// ];

export default function TodaySession() {
  const { data, loading } = useQuery(SESSION_FIND_TODAY);

  const navigate = useNavigate();
  // console.log('TodaySession',{ data });
  const onClickView = (id: string) => {
    navigate("/sessions/view/" + id);
  };
  const onClickChat = (id: string) => {
    navigate("/sessions/chat/" + id);
  };
  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Today's sessions
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell>In-Game Username</TableCell>
            <TableCell>On-stream/Off-stream </TableCell>
            <TableCell>Game</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={3}>
                <CircularProgress></CircularProgress>{" "}
              </TableCell>
            </TableRow>
          ) : data.sessionFindTodayForGamer.length == 0 ? (
            <TableRow>
              <TableCell colSpan={3}>No results</TableCell>
            </TableRow>
          ) : (
            data.sessionFindTodayForGamer.map((row: any) => {
              let isChatEnabled = false;
              if (row && row.end_date) {
                const dateX = moment(row.end_date);
                const hourDiff = moment(dateX).diff(moment(), "hours");
                isChatEnabled = hourDiff <= 1 && hourDiff >= -1;
              }
              return (
                <TableRow key={row._id}>
                  <TableCell>
                    {moment(row.start_date).format("hh:mmA")}
                  </TableCell>
                  <TableCell>{row.in_game_username}</TableCell>
                  <TableCell>
                    {row.session_type === "ON_STREAM"
                      ? "On-stream"
                      : "Off-stream"}
                  </TableCell>
                  <TableCell>{row.game.name}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => onClickView(row._id)}
                      variant={"contained"}
                      sx={{ marginRight: 1 }}
                    >
                      View
                    </Button>
                    {isChatEnabled && (
                      <Button
                        onClick={() => onClickChat(row._id)}
                        variant={"contained"}
                      >
                        Chat
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
