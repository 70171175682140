import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import HomeCarouselItemForm from "~/Components/HomeCarouselItemForm";
import { HOME_CAROUSEL_ITEM_FIND_BY_ID } from "~/Queries/HomeCarouselItems/HomeCarouselItems.query";


export default () => {
    const { id } = useParams();

    const { data, loading, error } = useQuery(HOME_CAROUSEL_ITEM_FIND_BY_ID, {
        variables: { id }
    })
    return (
        loading ? <CircularProgress /> : <HomeCarouselItemForm id={id} data={data.homeCarouselItemFindById} />
    )
}