import gql from 'graphql-tag';

import { SESSION_FIELDS } from './Session.fragment';
//#region Queries

export const SESSION_FIND_BY_ID = gql`
  ${SESSION_FIELDS}
  query sessionFindById($id: String!) {
    sessionFindById(id: $id) {
      ...SessionFields
    }
  }
`;

export const SESSION_FIND_NEXT = gql`
  ${SESSION_FIELDS}
  query sessionFindNextForGamer {
    sessionFindNextForGamer{
      ...SessionFields
    }
  }
`;

export const SESSION_FIND_TODAY= gql`
  ${SESSION_FIELDS}
  query sessionFindTodayForGamer {
    sessionFindTodayForGamer{
      ...SessionFields
    }
  }
`;






//#region Mutations



//#endregion Mutations
