import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import PlatformForm from "~/Components/PlatformForm";
import { PLATFORM_FIND_BY_ID } from "~/Queries/Platform/Platform.query";


export default () => {
    const { id } = useParams();

    const { data, loading, error } = useQuery(PLATFORM_FIND_BY_ID, {
        variables: { id }
    })
    return (
        loading ? <CircularProgress /> : <PlatformForm id={id} data={data.platformFindById} />


    )
}