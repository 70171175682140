import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import Divider from '@mui/material/Divider';
import moment from 'moment';
import { AvailableDate } from '~/Models/AvailableDate.model';


interface AvailableDateViewProps {
  item: AvailableDate
}

export default function AvailableDateView(props: AvailableDateViewProps) {
  const { item } = props;
  
  
  const startDateString = moment(item.start_date).set('hour', item.start_hour).set('minutes', item.start_minute).format('MM/DD/YYYY hh:mmA')
  const endDateString = moment(item.start_date).set('hour', item.end_hour).set('minutes', item.end_minute).format('MM/DD/YYYY hh:mmA')

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
      }}
    >
      <Divider variant="inset" component="li" />
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <CalendarTodayIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Start" secondary={startDateString} />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <CalendarTodayIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="End" secondary={endDateString} />
      </ListItem>
      <Divider variant="inset" component="li" />

    </List>
  );
}