export const TOKEN_KEY = '@token';

export default class AuthUtility {
  static async getToken(): Promise<string | null> {
    const token = await localStorage.getItem(TOKEN_KEY);
    return token;
  }

  static async setToken(token: string): Promise<void> {
    await localStorage.setItem(TOKEN_KEY, token);
  }
  
  static async removeToken(): Promise<void> {
    await localStorage.removeItem(TOKEN_KEY);
  }
  static async checkIfLoggedIn(): Promise<boolean> {
    const token = await localStorage.getItem(TOKEN_KEY);
    return token ? true : false;
  }
}
