/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import { SideBarItems } from './SidebarItems';
import { Box, Divider } from '@mui/material';
import Logo from './Logo';
import theme from '~/Theme/theme';
import { SetStateAction } from 'react';

export const sidebarWidth: number = 240;


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop: string) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: sidebarWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const StyledLogoContainer = styled('div')`
     flex: 1;
    margin: 20px;
`

interface SideBarProps {
    setOpen: (b: SetStateAction<boolean>) => void;
    open?: boolean;
};

export default ({ setOpen, open }: SideBarProps) => (
    <Box sx={{ display: 'flex', height: '100vh' }}>
        <Drawer variant="permanent" open={open}>
            <div>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <StyledLogoContainer><Logo /></StyledLogoContainer>

                </Toolbar>

                <List component="nav">
                    <SideBarItems setOpen={setOpen} />
                </List>
            </div>
        </Drawer>
    </Box>
)