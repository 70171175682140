import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PeopleIcon from '@mui/icons-material/People';

import Divider from '@mui/material/Divider';
import moment from 'moment';
import { Session } from '~/Models/Session.model';

import AvatarUtility from '~/Utilities/Avatar/Avatar.utility';


interface SessionViewProps {
  item: Session
}

export default function SessionView(props: SessionViewProps) {
  const { item } = props;
  
  
  const startDateString = moment(item.start_date).format('MM/DD/YYYY hh:mmA')
  const endDateString = moment(item.end_date).format('MM/DD/YYYY hh:mmA')

  const renderUser = () => {
    // Avatar
    const avatar = AvatarUtility.getAvatar(item.user.avatar_sku)
    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar src={avatar ? avatar.image : ''}>
            <PeopleIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="User" secondary={item.user.first_name + " " + item.user.last_name} />
      </ListItem>
    );
  }
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
      }}
    >
      {item.user && renderUser()}
      {item.game &&
        <ListItem>
          <ListItemAvatar>
            <Avatar src={item.game.images && item.game.images.length > 0 ? item.game.images[0].original as string : ""}>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Game" secondary={item.game.name} />
        </ListItem>
      }
      <Divider variant="inset" component="li" />
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <CalendarTodayIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Start" secondary={startDateString} />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <CalendarTodayIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="End" secondary={endDateString} />
      </ListItem>
      <Divider variant="inset" component="li" />

    </List>
  );
}