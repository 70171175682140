import React, { useState } from 'react';
import { Avatar, Paper, Typography } from '@mui/material';

import ListPage from '~/Components/ListPage';
import { GAMER_USER_DELETE, GAME_USER_LIST_BY_USER } from '~/Queries/GameGamer/GameGamer.query';
import { GridColumns } from '@mui/x-data-grid';
import GameForm from '~/Components/GameForm';




const headCells: GridColumns = [
    { field: '_id', headerName: 'Id', hide: true },
    {
        field: 'image', headerName: 'Image', renderCell: (params) => {
            if (!params.row.game.images) {
                return <Avatar alt="Image" variant="square" >No image</Avatar>
            }
            return <Avatar variant="square" src={params.row.game.images[0].sm} alt="Image" />
        }
    },

    { field: 'game.name', headerName: 'Name', width: 200, valueGetter: ({ row }) => row.game.name }

];



export default function GameIndexPage() {
    let [incremental, setIncremental] = useState<number>(0)
    return (
        <>
            <Paper sx={{ marginBottom: 5 }}>
                <GameForm
                    onSuccess={() => window.location = window.location}
                ></GameForm>
            </Paper>
            <Typography variant="h1" sx={{marginBottom: 2}}>Games</Typography>
            <ListPage i={incremental} query={GAME_USER_LIST_BY_USER} headCells={headCells} deleteQuery={GAMER_USER_DELETE}  />
        </>
    );
}


