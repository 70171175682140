import gql from 'graphql-tag';

export const PLATFORM_FIELDS = gql`
  fragment PlatformFields on Platform {
    _id
    name
    description
    active
    created_at
    updated_at
  }
`;
