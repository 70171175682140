import gql from 'graphql-tag';

import { REPORTED_ISSUE_FIELDS } from './ReportedIssue.fragment';
//#region Queries

//#endregion Queries

//#region Mutations
export const REPORTED_ISSUE_ADD = gql`
  ${REPORTED_ISSUE_FIELDS}
  mutation reportedIssueAdd($data: ReportedIssueInput!) {
    reportedIssueAdd(data: $data) {
      ...ReportedIssueFields
    }
  }
`;

export const REPORTED_ISSUE_LIST = gql`
  ${REPORTED_ISSUE_FIELDS}
  query reportedIssueList{
    reportedIssueList{
      ...ReportedIssueFields
    }
  }
`;


//#endregion Mutations
