/* eslint-disable import/no-anonymous-default-export */
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { authUserAtom } from "./Recoil/Auth.recoil";

import AdminLayout from "./Layout/Admin";
import LoginLayout from "./Layout/Login";

import HomePage from "./Pages/HomePage";
import UsersPage from "./Pages/UsersPage";
import LoginPage from "./Pages/LoginPage";
import ReportedIssuesPage from "./Pages/ReportedIssuesPage";
import GamesIndexPage from "./Pages/Game/GameIndexPage";
import HomeCarouselItemPage from "./Pages/HomeCarouselItemsPage";
import HomeCarouselItemAddPage from "./Pages/HomeCarouselItem/HomeCarouselItemAddPage";
import HomeCarouselItemEditPage from "./Pages/HomeCarouselItem/HomeCarouselItemEditPage";
// import UserAddPage from "./Pages/Profile/ProfilePage";
// import UserEditPage from "./Pages/Profile/ProfileEditPage";
import GameAddPage from "./Pages/Game/GameAddPage";
import PlatformPage from "./Pages/PlatformPage";
import PlatformAddPage from "./Pages/Platform/PlatformAddPage";
import PlatformEditPage from "./Pages/Platform/PlatformEditPage";
import SessionCalendarPage from "./Pages/SessionCalendarPage";
import AvailableDateIndexPage from "./Pages/AvailableDate/AvailableDateIndexPage";
import AvailableDateAddPage from "./Pages/AvailableDate/AvailableDateAddPage";
import AvailableDateEditPage from "./Pages/AvailableDate/AvailableDateEditPage";
import SessionChatPage from "./Pages/SessionChat/SessionChatPage";
import SessionViewPage from "./Pages/Session/SessionViewPage";
import LogoutPage from "./Pages/LogoutPage";
import ProfilePage from "./Pages/Profile/ProfilePage";
import ProfileEditPage from "./Pages/Profile/ProfileEditPage";
import PayoutRequestIndexPage from "./Pages/PayoutRequest/PayoutRequestIndexPage";
import PayoutRequestAddPage from "./Pages/PayoutRequest/PayoutRequestAddPage";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage";
import PayoutRequesViewPage from "./Pages/PayoutRequest/PayoutRequesViewPage";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const authUser = useRecoilValue(authUserAtom);

  return (
    <Routes>
      {!authUser && (
        <Route
          element={
            <LoginLayout>
              <Outlet />
            </LoginLayout>
          }
        >
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        </Route>
      )}
      {authUser && (
        <Route
          element={
            <AdminLayout>
              <Outlet />
            </AdminLayout>
          }
        >
          <Route path="/" element={<HomePage />} />
          <Route path="/logout" element={<LogoutPage />} />

          <Route path="/sessions">
            <Route
              path="/sessions/calendar"
              element={<SessionCalendarPage />}
            />
            <Route
              path="/sessions/available-dates"
              element={<AvailableDateIndexPage />}
            />
            <Route
              path="/sessions/available-dates/add"
              element={<AvailableDateAddPage />}
            />
            <Route
              path="/sessions/available-dates/edit/:id"
              element={<AvailableDateEditPage />}
            />
            <Route path="/sessions/chat/:id" element={<SessionChatPage />} />
            <Route path="/sessions/view/:id" element={<SessionViewPage />} />
          </Route>

          <Route path="/payout-requests">
            <Route
              path="/payout-requests"
              element={<PayoutRequestIndexPage />}
            />
            <Route
              path="/payout-requests/add"
              element={<PayoutRequestAddPage />}
            />
            <Route
              path="/payout-requests/view/:id"
              element={<PayoutRequesViewPage />}
            />
          </Route>

          <Route path="/home-carousel-items">
            <Route
              path="/home-carousel-items"
              element={<HomeCarouselItemPage />}
            />
            <Route
              path="/home-carousel-items/add"
              element={<HomeCarouselItemAddPage />}
            />
            <Route
              path="/home-carousel-items/edit/:id"
              element={<HomeCarouselItemEditPage />}
            />
          </Route>
          <Route path="/profile">
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/profile/edit" element={<ProfileEditPage />} />
          </Route>

          <Route path="/gamers">
            <Route path="/gamers" element={<UsersPage type="gamer" />} />
            <Route
              path="/gamers/inactive"
              element={<UsersPage type="gamer" active={false} />}
            />
          </Route>

          <Route path="/admin">
            <Route path="/admin" element={<UsersPage type="admin" />} />
            <Route
              path="/admin/inactive"
              element={<UsersPage type="user" active={false} />}
            />
          </Route>

          <Route path="/games">
            <Route path="/games" element={<GamesIndexPage />} />
            <Route path="/games/add" element={<GameAddPage />} />
          </Route>

          <Route path="/platforms">
            <Route path="/platforms" element={<PlatformPage />} />
            <Route path="/platforms/add" element={<PlatformAddPage />} />
            <Route path="/platforms/edit/:id" element={<PlatformEditPage />} />
          </Route>

          <Route path="/reported-issues" element={<ReportedIssuesPage />} />
        </Route>
      )}

      <Route path="*" element={<Navigate to={authUser ? "/" : "/login"} />} />
    </Routes>
  );
};
