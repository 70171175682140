import { Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from "@mui/material";
import Form from "~/Components/Form";

import { PLATFORM_ADD, PLATFORM_UPDATE } from "~/Queries/Platform/Platform.query";


class PlatformInputModel {
    name!: string;
    active?: boolean = true;
}

const defaultData: PlatformInputModel = {
    name: "",
    active: true
}
interface PlatformFormInterface {
    id?: string | null;
    data?: any,
}

export default function PlatformForm(props:PlatformFormInterface) {

    return (
        <Form model={PlatformInputModel} createMutation={PLATFORM_ADD} updateMutation={PLATFORM_UPDATE} backLink="/platforms" data={props.data || defaultData} id={props.id}>
            {({ errors, handleBlur, setFieldValue, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">

                        <TextField
                            label="Name"
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                        />
                    </FormControl>
                    {touched.name && errors.name && (
                        <FormHelperText error id="standard-weight-helper-text-email-login">
                            {errors.name}
                        </FormHelperText>
                    )}

                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <FormControlLabel control={<Checkbox defaultChecked
                            name="active"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.active}
                        />} label="Active" />
                    </FormControl>


                </>

            )
            }
        </Form >
    )
}