import gql from 'graphql-tag';

import { GAME_GAMER_FIELDS } from './GameGamer.fragment';
//#region Queries

export const GAME_USER_LIST_BY_USER = gql`
  ${GAME_GAMER_FIELDS}
  query gameUserListByUser{
    gameUserListByUser{
        ...GameGamerFields
    }
  }
`;




export const GAMER_USER_ADD = gql`
  ${GAME_GAMER_FIELDS}
  mutation gameUserAdd($data: GameGamerInput!){
    gameUserAdd(data: $data){
        ...GameGamerFields
    }
  }
`;


export const GAMER_USER_UPDATE = gql`
  ${GAME_GAMER_FIELDS}
  mutation gameUserUpdate($id: String!, $data: GameGamerInput!){
    gameUserUpdate(id: $id, data: $data){
        ...GameGamerFields
    }
  }
`;

export const GAMER_USER_DELETE = gql`
  ${GAME_GAMER_FIELDS}
  mutation gameUserDelete($id: String!){
    gameUserDelete(id: $id){
        ...GameGamerFields
    }
  }
`;