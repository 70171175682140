import gql from 'graphql-tag';

import { GAME_FIELDS } from './Game.fragment';
//#region Queries

export const GAME_TOP_LIST = gql`
  ${GAME_FIELDS}
  query gameTopList($cant: Float!) {
    gameTopList(cant: $cant) {
      ...GameFields
    }
  }
`;
export const GAME_LIST = gql`
  ${GAME_FIELDS}
  query gameList {
    gameList {
      ...GameFields
    }
  }
`;

export const GAME_FIND_BY_ID = gql`
  ${GAME_FIELDS}
  query gameFindById($game_id: String!) {
    gameFindById(game_id: $game_id) {
      ...GameFields
    }
  }
`;

//#endregion Queries

//#region Mutations

export const GAME_ADD = gql`
  ${GAME_FIELDS}
  mutation gameAdd($data: GameInput!) {
    gameAdd(data: $data) {
      ...GameFields
    }
  }
`;


export const GAME_UPDATE = gql`
  ${GAME_FIELDS}
  mutation gameUpdate($id: String! , $data: GameInput!) {
    gameUpdate(id: $id, data: $data) {
      ...GameFields
    }
  }
`;


export const GAME_DELETE = gql`
  ${GAME_FIELDS}
  mutation gameDelete($id: String! ) {
    gameDelete(id: $id) {
      ...GameFields
    }
  }
`;

//#endregion Mutations
