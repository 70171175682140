import gql from 'graphql-tag';

//#region Queries

export const PAYMENT_STATISTICS = gql`
  query paymentStatistics{
    paymentStatistics{
      earnings_past_year
      earnings_past_30_days
      earnings_past_7_days
      earnings_today
      earnings_yesterday
      earnings_next_30_days
      earnings_next_3_months
    }
  }
`;
