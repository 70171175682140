import React from 'react';
import AvailableDateForm from "~/Components/AvailableDateForm";


const AvailableDateAddPage =  () => {
    return (
        <AvailableDateForm />
    )
}

export default AvailableDateAddPage;