import { useQuery } from "@apollo/client";
import { Alert, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import PayoutRequestView from "~/Components/PayoutRequest/PayoutRequestView";
import { PayoutRequest } from "~/Models/PayoutRequest.model";
import { PAYOUT_REQUEST_FIND_BY_ID } from "~/Queries/PayoutRequest/PayoutRequest.query";



const PayoutRequesViewPage = () => {
    const { id } = useParams();

    const { data, loading, error } = useQuery(PAYOUT_REQUEST_FIND_BY_ID, {
        variables: { id: id }
    })
    
    if (error) {
        return <Alert severity="error">{error.message ? error.message : 'Oops! An error has ocurred...Try again later!'}</Alert>

    }
    return (
        loading ? <CircularProgress /> : <PayoutRequestView data={data.payoutRequestFindById as PayoutRequest} />


    )
}

export default PayoutRequesViewPage;