import { BaseModel } from "~/Models/Base.model";
import { GameGamerPlatform } from "~/Models/GameGamerPlatform.model";
import { Image } from "~/Models/Image.model";

export class User extends BaseModel {
  type!: string[];
  image!: string | null;
  cover!: string | null;
  images: Image[] = [];
  covers: Image[] = [];
  cover_page!: string | null;
  first_name!: string;
  last_name!: string;
  company_name!: string;
  url!: string;
  email!: string;
  // Username :p
  name!: string;
  birthday!: Date;
  update_at!: Date;
  about_me!: string;
  phone!: string;
  sex!: string;
  color_blind_mode = false;
  stars?: number = 0;
  facebook?: string;
  instagram?: string;
  twitch?: string;
  disabled_dates?: string[] = [];
  on_stream_price?: number;
  off_stream_price?: number;
  //...
  games: GameGamerPlatform[] = [];
  // favorite
  favorite = false;
  favorites_gamers: User[] = [];

  address?: string;

  password?: string;
  file?: File;

  account_holder_name?: string;

  account_holder_type?: string;

  account_type?: "checking" | "savings";

  account_number?: number;

  bank_name?: string;

  account_routing_number?: string;
  facebook_url?: string;

  twitch_url?: string;

  instagram_url?: string;

  profit_percentage: number = 30;

  // Avatar
  avatar_sku?: string;
}

export class UserInputModel {
  name!: string;
  type!: string[];

  first_name?: string;

  last_name?: string;
  display_name_key?: "fullname" | "username";

  password?: string;

  email!: string;

  phone?: string;

  sex?: string;

  url?: string;

  company_name?: string;

  image?: string;

  about_me?: string;
  address?: string;

  files?: [File];
  active?: boolean;
  created_at?: Date;
  updated_at?: Date;

  account_holder_name?: string;

  account_holder_type?: string;

  account_type?: "checking" | "savings";
  account_number?: number;

  bank_name?: string;

  account_routing_number?: string;

  facebook_url?: string;

  twitch_url?: string;

  instagram_url?: string;

  price_off_stream? = 0;
  price_on_stream? = 0;
  profit_percent_off_stream? = 0;
  profit_percent_on_stream? = 0;
}
