import { AVATARS } from '~/Mock/Avatar.mock';
import { Avatar } from '~/Models/Avatar.model';



export default class AvatarUtility {
  static getAvatar(sku: string | undefined): Avatar | undefined {
    if (!sku) {
      return undefined;
    }
    const avatar = AVATARS.find((a) => a.sku === sku);
    return avatar;
  }
}
