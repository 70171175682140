
import React, { useCallback, useEffect, useState } from 'react';

import { Card, CardContent, Fab, Grid, Stack, Typography } from '@mui/material';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';
import { PayoutRequest } from '~/Models/PayoutRequest.model';


const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1000
};


interface PayoutRequestViewProps {
    data: PayoutRequest
}

export default function PayoutRequestView(props: PayoutRequestViewProps) {
    // Navigation
    const navigate = useNavigate();
    // Rate Session
    const [payoutRequest, setPayoutRequest] = useState<PayoutRequest | null>(null)
    // Props
    const { data } = props;

    const fetchData = useCallback(() => {
        setPayoutRequest({ ...data })
    }, [data])
    useEffect(() => {
        fetchData()
    }, [data, fetchData])

    if (!payoutRequest) {
        return null;
    }

    return (
        <Card sx={{ maxWidth: 800 }}>

            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Stack >
                            <Typography variant="h5" >
                                ID:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {`${payoutRequest._id} `}
                            </Typography>
                        </Stack>
                        <Stack mt={2}>
                            <Typography variant="h5" >
                                Status:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {`${payoutRequest.status} `}
                            </Typography>
                        </Stack>
                        {/* Ammount */}
                        <Stack mt={2}>
                            <Typography variant="h5" >
                                Ammount:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {`$${payoutRequest.decimal_amount}`}
                            </Typography>
                        </Stack>
                        <Stack mt={2}>
                            <Typography variant="h5" >
                                Requested:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {`${payoutRequest.created_at} `}
                            </Typography>
                        </Stack>
                        {payoutRequest.reason &&
                            <Stack mt={2}>
                                <Typography variant="h5" >
                                    Reason:
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {`${payoutRequest.reason} `}
                                </Typography>
                            </Stack>}
                    </Grid>
                </Grid>

                <Fab color="primary" aria-label="add" sx={fabStyle} onClick={() => navigate(-1)}>
                    <NavigateBeforeIcon />
                </Fab>
            </CardContent>

        </Card>
    )
}