import gql from 'graphql-tag';

import { SESSION_MESSAGE_FIELDS } from './SessionMessage.fragment';
//#region Queries

export const SESSION_MESSAGE_LIST = gql`
  ${SESSION_MESSAGE_FIELDS}
  query sessionMessageList($id: String!) {
    sessionMessageList(id: $id) {
      ...SessionMessageFields
    }
  }
`;


export const SESSION_MESSAGE_CREATE_SUBSCRIPTION = gql`
${SESSION_MESSAGE_FIELDS}
  subscription newSessionMessage($session_id: String!) {
    newSessionMessage(session_id: $session_id) {
      ...SessionMessageFields
    }
  }
`;

//#region Mutations


export const SESSION_MESSAGE_CREATE = gql`
  ${SESSION_MESSAGE_FIELDS}
  mutation sessionMessageCreate($data: SessionMessageInput!) {
    sessionMessageCreate(data: $data) {
      ...SessionMessageFields
    }
  }
`;
//#endregion Mutations
