import { useQuery } from '@apollo/client';
import { CircularProgress, Alert } from '@mui/material';

import { PAYOUT_REQUEST_LIST } from '~/Queries/PayoutRequest/PayoutRequest.query';
import PayoutRequestDataGrid from '~/Components/PayoutRequest/PayoutRequestDataGrid';






export default function PayoutRequestIndexPage() {

    const { loading, error, data } = useQuery(PAYOUT_REQUEST_LIST);
    if (loading) {
        return <CircularProgress />
    }
    if (error) {
        return <Alert severity="error">{error.message ? error.message : 'Oops! An error has ocurred...Try again later!'}</Alert>

    }
    return (
        <PayoutRequestDataGrid data={data.payoutRequestList} />
    );
}


