
import { Box, Grid, Stack, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import moment from 'moment';

import ListPage from '~/Components/ListPage';
import { AVAILABLE_DATE_DELETE, AVAILABLE_DATE_FIND_BY_USER_ID } from '~/Queries/AvailableDate/AvailableDate.query';



interface AvailableDotProps {
    bgColor: string;
    text: string;
}
const AvailableDot = ({ bgColor, text }: AvailableDotProps) => {
    const shapeStyles = { display: 'block', width: 10, height: 10, borderRadius: '50%', bgcolor: bgColor };
    return (
        <Stack sx={{ width: '100%' }} flexDirection="row" alignItems={'center'}>
            <Box component="span" sx={{ ...shapeStyles }}  ml={1}/>
            <Typography ml={2}>{`${text}`}</Typography>
        </Stack>
    )
};


function hours12(h: number) {
    let ampm = (h < 12 || h === 24) ? "AM" : "PM";

    return ((h + 24) % 12 || 12) + ampm;
}

const headCells: GridColumns = [

    {
        field: 'available', width: 120, headerName: 'Available', renderCell: (item) => {

            return (<AvailableDot bgColor={item.row.available ? 'success.main' : 'error.main'} text={item.row.available ? 'Available' : 'Blocked'} />);
        }
    },
    {
        field: 'start_date', headerName: 'Start date', width: 150, renderCell: (item) => {

            return moment(item.row.start_date).format("MM/DD/YYYY")
        }
    },
    {
        field: 'end_date', headerName: 'End date', width: 150, renderCell: (item) => {

            return item.row.end_date ? moment(item.row.end_date).format("MM/DD/YYYY") : '∞'
        }
    },
    { field: 'start_hour', headerName: 'Start hour', renderCell: (item) => hours12(item.row.start_hour) },
    { field: 'start_minute', headerName: 'Start minute' },
    { field: 'end_hour', headerName: 'End Hour', renderCell: (item) => hours12(item.row.end_hour) },
    { field: 'end_minute', headerName: 'End minute' },
    { field: 'session_type', headerName: 'Session Type' },

];



export default function AvailableDateIndexPage() {

    return (
        <ListPage query={AVAILABLE_DATE_FIND_BY_USER_ID} headCells={headCells} deleteQuery={AVAILABLE_DATE_DELETE} addActionUrl={'/sessions/available-dates/add'} editActionUrl={'/sessions/available-dates/edit'} />
    );
}


