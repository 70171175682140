import { useState } from 'react';
import { DatePicker } from '@mui/lab';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import { Box, FormControl, Grid, TextField, TextFieldProps } from '@mui/material';
//tabs
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import { Moment } from 'moment';


interface AvailableDatePickerProps {
    startDate: Moment | null;
    endDate: Moment | null;
    onStartDateChange: (d: Moment | null) => void;
    onEndDateChange: (d: Moment | null) => void;
}

export default function AvailableDatePicker(props: AvailableDatePickerProps) {
    const [tabValue, setTabValue] = useState(props.endDate ? '2' : '1');

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                            <Tab label="Continuous Calendar" value="1" />
                            <Tab label="Fixed Calendar" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ paddingLeft: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12} >
                                <FormControl margin="dense">
                                    <DatePicker
                                        label="Valid from"
                                        value={props.startDate}
                                        onChange={(newValue) => {
                                            props.onStartDateChange(newValue);
                                        }
                                        }
                                        inputFormat="MM/DD/YYYY"
                                        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="2" sx={{ paddingLeft: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12} >
                                <FormControl margin="dense">
                                    <DateRangePicker
                                        startText="Valid from"
                                        endText="Valid until"
                                        value={[props.startDate, props.endDate] as DateRange<Date>}
                                        inputFormat="MM/DD/YYYY"


                                        onChange={(newValue: DateRange<Moment>) => {
                                            props.onStartDateChange(newValue[0]);
                                            props.onEndDateChange(newValue[1]);
                                        }}

                                        renderInput={(startProps: JSX.IntrinsicAttributes & TextFieldProps, endProps: JSX.IntrinsicAttributes & TextFieldProps) => (
                                            <React.Fragment>
                                                <TextField {...startProps} helperText={startProps?.inputProps?.placeholder} />
                                                <Box sx={{ mx: 2 }}> to </Box>
                                                <TextField {...endProps} helperText={endProps?.inputProps?.placeholder} />
                                            </React.Fragment>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </TabPanel>

                </TabContext>
            </Box>
        </Box>
    )
}