interface LogoProps {
    className?:string;
}

export default ({ className } : LogoProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.57 64.5" className={className}>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Capa_1" data-name="Capa 1">
                <path d="M6.11,45.33a13.62,13.62,0,0,0,10.24,1.46,11.81,11.81,0,0,0,5.59-3.17,2.35,2.35,0,0,1,2-.79,27.84,27.84,0,0,0,3,0A10.14,10.14,0,0,1,34.41,45c5.2,4,12.85,2.59,16.87-2.64.12-.16.25-.3.36-.44.54.23.29.64.29.94,0,6.46,0,12.92,0,19.39,0,1.2-.21,1.65-1.54,1.62-5.7-.11-11.44.26-17.11-.2C21,62.65,12,56.25,6.11,45.33ZM27.26,50.8l4.35-6H22.88Z" fill="#fff" />
                <path d="M51.66.08c.49.44.27.86.27,1.23q0,10.83,0,21.65a4.37,4.37,0,0,0,.67,2.41c2,3.23,2.59,7.77,1.43,10.57-.36-.09-.2-.38-.2-.57.09-7.45-5.09-13-12.54-13-9.34-.11-18.68,0-28,0A12.33,12.33,0,0,0,.69,35.61a7.26,7.26,0,0,1,0,.94c-.92-1.74-1-7.14.28-9.27,1.6-2.59,1.64-5.27,1.61-8.1,0-6.3,0-12.59,0-19.18A15.56,15.56,0,0,1,4.71,3.25C6.27,5.9,7.8,8.56,10.23,10.57a16.87,16.87,0,0,0,9.42,4.15,77.24,77.24,0,0,0,15.43-.06A17.39,17.39,0,0,0,48,6.15C49.28,4.17,50.44,2.13,51.66.08Z" fill="#fff" />
                <path d="M57.94,45.49c2-.61,2.38.28,2.51,1.81.36,4,.87,7.93,1.31,11.89.1.88.19,1.77,1.38,1.82s1.36-.85,1.56-1.7c.82-3.43,1.61-6.88,2.45-10.31.69-2.87,2.29-4,5.36-4,2.81,0,4.3,1.25,4.95,4.13.77,3.4,1.54,6.79,2.28,10.2.2.89.44,1.71,1.57,1.7s1.2-1,1.32-1.84c.56-4.15,1.17-8.3,1.66-12.46.13-1.09.48-1.43,1.58-1.41,2.77.05,2.78,0,2.4,2.7-.56,4-1.1,7.9-1.7,11.84-.48,3.09-2.09,4.51-5,4.58s-4.67-1.14-5.38-4.18q-1.15-5-2.25-10.05c-.21-.92-.35-1.86-1.59-1.83s-1.33.84-1.53,1.73c-.77,3.35-1.55,6.69-2.37,10-.93,3.77-4.41,5.39-8,3.76a4,4,0,0,1-2.34-3.31q-1-7.11-1.9-14.25C56,44.34,57.62,45.85,57.94,45.49Z" fill="#fff" />
                <path d="M14.62,25.63c-1.65,2.86-1.46,5.18.52,7s4.53,1.72,7-.22c1.49,2.75.29,7.39-2.56,9.93A9.91,9.91,0,0,1,7.27,43a9.66,9.66,0,0,1,7.35-17.39Z" fill="#fff" />
                <path d="M50.78,32.18a9.53,9.53,0,0,1-4,11.15,10,10,0,0,1-12-1.19A9.69,9.69,0,0,1,32.9,30.48c2-3.66,6.39-5.78,10-4.82-1.56,3.06-1.31,5.3.79,7S48.05,34.24,50.78,32.18Z" fill="#fff" />
                <path d="M151.49,64c-1.83-.11-4.05.22-6.26-.2A5,5,0,0,1,140.76,59a45,45,0,0,1,0-8.61,4.88,4.88,0,0,1,4.43-4.81,35.89,35.89,0,0,1,10.4,0c2.61.4,4,2.43,4,5.42s-1.26,4.68-4,5.17c-3.05.55-6.15.08-9.21.27-.66,0-1.72-.66-2,.6a2.46,2.46,0,0,0,1.76,3.07,15.84,15.84,0,0,0,3.77.15c2.47,0,5,.07,7.42,0,1.17-.05,1.54.35,1.36,1.45a3.39,3.39,0,0,0,0,.9c.16,1.07-.29,1.41-1.35,1.36C155.51,63.91,153.69,64,151.49,64Zm-2.11-10.51c1.26,0,2.53,0,3.79,0,1.75,0,2.62-.7,2.68-2,.08-1.51-.83-2.4-2.62-2.45s-3.74,0-5.61,0c-2.59,0-3.42,1-3.3,3.54,0,.73.25,1,1,.94C146.65,53.42,148,53.45,149.38,53.45Z" fill="#fff" />
                <path d="M128.44,64a51.25,51.25,0,0,1-6.07-.15c-2.78-.45-4.45-2-4.72-4.71a39.78,39.78,0,0,1,0-9.06,4.85,4.85,0,0,1,4.56-4.51,39,39,0,0,1,9.66,0c3.06.39,4.63,2.44,4.59,5.56S135,56,131.79,56.3c-2.85.25-5.75.06-8.62.17-.64,0-1.77-.68-1.91.68s.08,2.48,1.53,2.93a6.7,6.7,0,0,0,1.79.21c3.23,0,6.46.05,9.69,0,1.08,0,1.47.31,1.32,1.35a4.9,4.9,0,0,0,0,1.21c.09.85-.24,1.17-1.11,1.14C132.61,63.92,130.74,64,128.44,64Zm-2.1-10.51H130c1.85,0,2.69-.68,2.73-2.1S131.84,49,130,49s-3.74,0-5.6,0c-2.51,0-3.29.92-3.23,3.45,0,.8.26,1.07,1.05,1C123.62,53.42,125,53.45,126.34,53.45Z" fill="#fff" />
                <path d="M96.12,57.24c0,1.81,0,3.63,0,5.44,0,1-.26,1.31-1.27,1.3-2.58,0-2.58,0-2.58-2.53V51a9.11,9.11,0,0,1,.35-2.84,3.53,3.53,0,0,1,3-2.65c2.91-.43,5.84-.11,8.76-.19.6,0,.71.35.77.87.29,2.76.3,2.76-2.45,2.76-1.41,0-2.83,0-4.24,0-1.59.06-2.29.75-2.33,2.35,0,2,0,3.93,0,5.9Z" fill="#fff" />
                <path d="M112.58,50.7c0,3.93,0,7.87,0,11.8,0,1.07-.19,1.54-1.39,1.49-2.91-.13-2.45.35-2.46-2.52,0-7.56,0-15.13,0-22.69,0-1.15.23-1.71,1.47-1.51a3.43,3.43,0,0,0,1.06,0c1-.14,1.36.25,1.35,1.33-.07,4,0,8.07,0,12.1Z" fill="#fff" />
            </g>
        </g>
    </svg>
)