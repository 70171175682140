import { DocumentNode, useApolloClient, useQuery } from '@apollo/client';
import { CircularProgress, Avatar, Fab, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar, GridActionsCellItem, GridColumns, GridRowId, GridDensity, GridDensityTypes } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';


import { GAME_LIST } from '~/Queries/Games/Game.query';
import { OperationDefinitionNode } from 'graphql';
import { useSnackbar } from 'notistack';



const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1000
};



interface ListPageProps {
    headCells: GridColumns,
    query: DocumentNode,
    deleteQuery?: DocumentNode,
    deleteQueryIdName?: string,
    addActionUrl?: string;
    editActionUrl?: string | null;
    i?: number;

}


function getDataIndex(documentNode: DocumentNode) {
    const operation = documentNode.definitions.find((def) => def.kind === 'OperationDefinition') as unknown as OperationDefinitionNode;
    return operation && operation.name ? operation.name.value : 'ERROR';
}


export default function ListPage(props: ListPageProps) {

    const { loading, error, data, refetch } = useQuery(props.query, {
        fetchPolicy: 'cache-and-network'
    });

    const { enqueueSnackbar } = useSnackbar();

    const client = useApolloClient();
    const navigate = useNavigate();

    if (loading) {
        return <CircularProgress />
    }


    const deleteItemAction = async (id: string) => {
        if (!props.deleteQuery) {
            return;
        }
        if (window.confirm("Do you really want to delete this item?")) {

            const response = await client.mutate(
                {
                    mutation: props.deleteQuery,
                    variables: { [props.deleteQueryIdName || "id"]: id }
                }
            )

            if (response.data[getDataIndex(props.deleteQuery)]) {
                refetch();
                enqueueSnackbar('The item has been deleted!', { variant: 'success' });
            } else {
                enqueueSnackbar('Error, please try again!', { variant: 'error' });
            }
        }
    };


    const onClickNavigateAdd = () =>{
        if(props.addActionUrl){
            navigate(props.addActionUrl);
        }
    }


    const headCells: GridColumns = [
        { field: '_id', headerName: 'Id', hide: true },
        ...props.headCells,
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            getActions: ({ id, getValue }) => {
                const _id = getValue(id, '_id') as string;

                let ret = [
                    <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={() => navigate(props.editActionUrl + "/" + _id)} />,
                    <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => deleteItemAction(_id)} />,
                ];
                if (!props.editActionUrl) {
                    ret.shift();
                }
                if (!props.deleteQuery) {
                    ret.pop();
                }
                return ret;
            },
        }
    ];

    if (error) {
        return <Alert severity="error">{error.message ? error.message : 'Oops! An error has ocurred...Try again later!'}</Alert>

    }

    return (
        <div style={{ height: '70vh', width: '100%' }}>

            <DataGrid
                density={GridDensityTypes.Comfortable}
                getRowId={(row) => row._id}
                rows={data[getDataIndex(props.query)]}
                columns={
                    headCells
                }
                components={{
                    Toolbar: GridToolbar,
                }}
            ></DataGrid>

            {props.addActionUrl && <Fab color="primary" aria-label="add" sx={fabStyle} onClick={onClickNavigateAdd}>
                <AddIcon />
            </Fab>}
        </div>
    );
}


