import gql from 'graphql-tag';

import { GAMER_FIELDS } from '../Gamer/Gamer.fragment';
import { USER_FIELDS } from './User.fragment';
//#region Queries

export const USER_DATA = gql`
  ${USER_FIELDS}
  query userData {
    userData {
      ...UserFields
    }
  }
`;


export const USER_FIND_BY_TYPE = gql`
  ${USER_FIELDS}
  query userFindByType($type: String!, $filter: UserInput) {
    userFindByType(type: $type,filter: $filter) {
      ...UserFields
    }
  }
`;


export const USER_FIND_BY_ID = gql`
  ${USER_FIELDS}
  query userFindById($user_id: String!) {
    userFindById(user_id: $user_id) {
      ...UserFields
    }
  }
`;

export const USER_NAME_EXISTS = gql`
  query userNameExists($name: String!) {
    userNameExists(name: $name)
  }
`;
export const USER_FAVORITE_GAMERS = gql`
  ${USER_FIELDS}
  ${GAMER_FIELDS}
  query userData {
    userData {
      ...UserFields
      favorites_gamers {
        ...GamerFields
      }
    }
  }
`;
//#endregion Queries

//#region Mutations
export const USER_LOGIN = gql`
  mutation userLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password)
  }
`;
export const USER_REGISTER = gql`
  mutation userRegister($data: UserInput!) {
    userRegister(data: $data) {
      _id
    }
  }
`;

export const USER_ADD = gql`
  ${USER_FIELDS}
  mutation userAdd($data: UserInput!) {
    userAdd(data: $data) {
      ...UserFields
    }
  }
`;
export const USER_UPDATE = gql`
${USER_FIELDS}
mutation userUpdate($id: String!, $data: UserInput!) {
  userUpdate(id:$id data: $data) {
    ...UserFields
  }
}
`;
export const USER_MODIFY = gql`
  ${USER_FIELDS}
  mutation userModify($data: UserInput!) {
    userModify(data: $data) {
      ...UserFields
    }
  }
`;

export const USER_CHANGE_PASSWORD = gql`
  mutation userChangePassword($user_id: String!, $password: String!) {
    userChangePassword(user_id: $user_id, password: $password) {
      _id
    }
  }
`;


export const USER_RECOVER = gql`
  mutation gamerRecoverPassword($email: String!) {
    gamerRecoverPassword(email: $email) {
      _id
    }
  }
`;

export const USER_VERIFY_VERIFICATION_CODE = gql`
mutation userVerifyVerificationCode($user_id: String!,$verification_code: String!) {
    userVerifyVerificationCode(user_id: $user_id,verification_code:$verification_code) 
  }
`;

export const USER_CHANGE_PASSWORD_WITH_VERIFICATION_CODE = gql`
  mutation userChangePassword($user_id: String!, $password: String!, $verification_code: String!) {
    userChangePassword(user_id: $user_id, password: $password, verification_code: $verification_code) {
      _id
    }
  }
`;

export const USER_UPLOAD_PROFILE_PHOTO = gql`
  ${USER_FIELDS}
  mutation userUploadProfilePhoto($user_id: String!, $image: Upload!) {
    userUploadProfilePhoto(user_id: $user_id, photo: { image: $image }) {
      ...UserFields
    }
  }
`;
export const USER_ADD_FAVORITE_GAMER = gql`
  ${USER_FIELDS}
  mutation userAddFavoriteGamer($gamer_id: String!) {
    userAddFavoriteGamer(gamer_id: $gamer_id) {
      ...UserFields
    }
  }
`;
export const USER_REMOVE_FAVORITE_GAMER = gql`
  ${USER_FIELDS}
  mutation userRemoveFavoriteGamer($gamer_id: String!) {
    userRemoveFavoriteGamer(gamer_id: $gamer_id) {
      ...UserFields
    }
  }
`;



export const USER_DELETE = gql`
  ${USER_FIELDS}
  mutation userDelete($user_id: String!) {
    userDelete(user_id: $user_id) {
      ...UserFields
    }
  }
`;

//#endregion Mutations
