import React, { useRef } from 'react';
import { Grid, Typography, Paper, List, Divider, TextField, Fab, CircularProgress, Alert } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useApolloClient, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import SessionChatMessages from '~/Components/SessionChat/SessionChatMessages';
import { SESSION_MESSAGE_CREATE_SUBSCRIPTION, SESSION_MESSAGE_LIST } from '~/Queries/SessionMessage/SessionMessage.query';
import { useCallback, useEffect, useState } from 'react';
import { SESSION_MESSAGE_CREATE } from '~/Queries/SessionMessage/SessionMessage.query';
import { useSnackbar } from 'notistack';
import { SESSION_FIND_BY_ID } from '~/Queries/Sessions/Session.query';

const useStyles = {
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: '80vh'
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
        height: '70vh',
        overflowY: 'auto'
    }
};

const SessionChat = () => {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const client = useApolloClient();
    const [message, setMessage] = useState<string>("")
    const scrollList = useRef<null | any>(null);


    const sessionResponse = useQuery(SESSION_FIND_BY_ID, {
        variables: { id }
    })

    const { data, loading, error, subscribeToMore } = useQuery(SESSION_MESSAGE_LIST, {
        variables: { id }
    })

    // const subscriptionResult = useSubscription(
    //     SESSION_MESSAGE_CREATE_SUBSCRIPTION,
    //     { variables: { session_id: id } }
    // );

    useEffect(() => {
        subscribeToMore({
            document: SESSION_MESSAGE_CREATE_SUBSCRIPTION,
            variables: { session_id: id },

            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const newSessionMessage = subscriptionData.data.newSessionMessage;

                return {
                    sessionMessageList: [...prev.sessionMessageList, newSessionMessage],
                };
            },
        });
    }, [id, subscribeToMore]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    };

    const scrollToBottom = () => {

        if (scrollList.current) {
            setTimeout(() => {
                scrollList.current.scrollIntoView({ behavior: "auto" })
            }, 300)


        }
    }

    const sendMessage = useCallback(async () => {
        try {
            const response = await client.mutate({
                mutation: SESSION_MESSAGE_CREATE,
                variables: {
                    data: {
                        session_id: id,
                        message
                    }
                }
            })
            if (response.data.sessionMessageCreate) {
                setMessage("");
            }

        } catch (err: any) {
            enqueueSnackbar(err.message, { variant: 'error' });
        }

    }, [client, id, message, enqueueSnackbar])

    const classes = useStyles;



    if (!id) {
        return null;
    }

    if (error) {
        return <Alert severity="error">{error.message ? error.message : 'Oops! An error has ocurred...Try again later!'}</Alert>

    }

    if (!loading) {
        scrollToBottom();
    }


    return (
        <div>
            <Grid container>
                <Grid item xs={12} >
                    <Typography variant="h5" className="header-message">Chat with {
                        sessionResponse.loading ? '' : sessionResponse.data.sessionFindById.user.first_name

                    }</Typography>
                </Grid>
            </Grid>
            <Grid container component={Paper} sx={classes.chatSection}>
                <Grid item xs={12}>
                    <List sx={classes.messageArea}>
                        {loading ? <CircularProgress /> : <SessionChatMessages items={data.sessionMessageList} />}
                        <div ref={scrollList} />
                    </List>
                    <Divider />
                    <Grid container style={{ padding: '5px', alignItems: "center", justifyContent: "space-between" }}>
                        <Grid item xs={11} sx={{ paddingRight: 3 }}>
                            <TextField value={message} onChange={handleChange} label="Type Something" fullWidth />
                        </Grid>
                        <Grid item xs={1} >
                            <Fab disabled={!message} color="primary" aria-label="add" onClick={sendMessage}><SendIcon /></Fab>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default SessionChat;