import gql from 'graphql-tag';

import { PAYOUT_REQUEST_FIELDS } from './PayoutRequest.fragment';

export const PAYOUT_REQUEST_LIST = gql`
  ${PAYOUT_REQUEST_FIELDS}
  query payoutRequestList {
    payoutRequestList {
      ...PayoutRequestFields
    }
  }
`;
export const PAYOUT_REQUEST_ADD = gql`
  ${PAYOUT_REQUEST_FIELDS}
  mutation payoutRequestAdd($data: PayoutRequestInput ! ) {
    payoutRequestAdd(data: $data) {
      ...PayoutRequestFields
    }
  }
`;


export const PAYOUT_REQUEST_FIND_BY_ID = gql`
  ${PAYOUT_REQUEST_FIELDS}
  query payoutRequestFindById($id: String!) {
    payoutRequestFindById(id: $id){
      ...PayoutRequestFields
    }
  }
`;