import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { SESSION_FIND_NEXT } from "~/Queries/Sessions/Session.query";
import { DATE_FORMAT, HOUR_FORMAT } from "~/Constants/Time.constant";

// Generate Order Data
function createData(id: number, date: string, name: string, game: string) {
  return { id, date, name, game };
}

const rows = [
  createData(0, "16 Mar, 2019", "Elvis Presley", "Spiderman / Xbox"),
  createData(1, "16 Mar, 2019", "Paul McCartney", "Spiderman / Xbox"),
  createData(3, "16 Mar, 2019", "Michael Jackson", "Spiderman / Xbox"),
  createData(4, "15 Mar, 2019", "Bruce Springsteen", "Spiderman / Xbox"),
];

export default function NextSessions() {
  const { data, loading } = useQuery(SESSION_FIND_NEXT);
  console.log({ data });

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Next sessions
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>In-Game Username</TableCell>
            <TableCell>On-stream/Off-stream </TableCell>
            <TableCell>Game</TableCell>
            <TableCell>Expected Earnings</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={3}>
                <CircularProgress></CircularProgress>{" "}
              </TableCell>
            </TableRow>
          ) : data.sessionFindNextForGamer.length == 0 ? (
            <TableRow>
              <TableCell colSpan={3}>No results</TableCell>
            </TableRow>
          ) : (
            data.sessionFindNextForGamer.map((row: any) => (
              <TableRow key={row._id}>
                <TableCell>
                  {moment(row.start_date).format(DATE_FORMAT)}
                </TableCell>
                <TableCell>
                  {moment(row.start_date).format(HOUR_FORMAT)}
                </TableCell>
                <TableCell>{row.in_game_username}</TableCell>
                <TableCell>
                  {row.session_type === "ON_STREAM"
                    ? "On-stream"
                    : "Off-stream"}
                </TableCell>
                <TableCell>{row.game.name}</TableCell>
                <TableCell>
                  $
                  {row.payment?.decimal_to_user_amount
                    ? row.payment?.decimal_to_user_amount
                    : 0}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Link color="primary" href="/sessions/calendar" sx={{ mt: 3 }}>
        See all sessions
      </Link>
    </React.Fragment>
  );
}
