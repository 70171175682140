import React from 'react';

import { DataGrid, GridToolbar, GridActionsCellItem, GridColumns, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import PreviewIcon from '@mui/icons-material/Preview';
import { useNavigate } from 'react-router-dom';
import { PayoutRequest } from '~/Models/PayoutRequest.model';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import moment from 'moment';
import { DATE_FORMAT_LARGE } from '~/Constants/Time.constant';



const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1000
};




interface PayoutRequestDataGridProps {
    data: Array<PayoutRequest>
}

export default function PayoutRequestDataGrid({ data }: PayoutRequestDataGridProps) {

    // Navigation
    const navigate = useNavigate();


    // On Click Edit
    const onClickView = (params: GridRowParams) => {
        navigate(`/payout-requests/view/${params.id}`);

    }
    const onClickAdd = () => {
        navigate(`/payout-requests/add/`);
    }
    // Columns
    const headCells: GridColumns = [
        { field: '_id', headerName: 'Id', hide: true },
        { field: 'status', headerName: 'Status' },
        { field: 'amount', headerName: 'Amount', valueGetter: ({ row }) => "$" + row.decimal_amount },
        { field: 'created_at', headerName: 'Created', width: 180, valueGetter: ({ row }) => moment(row.created_at).format(DATE_FORMAT_LARGE) },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem icon={<PreviewIcon />} label="View" onClick={() => onClickView(params)} />
            ],
        }
    ];


    return (
        <div style={{ height: '70vh', width: '100%' }}>

            <DataGrid

                getRowId={(row) => row._id}
                rows={data}
                columns={
                    headCells
                }
                components={{
                    Toolbar: GridToolbar,
                }}
            ></DataGrid>
            <Fab color="primary" aria-label="add" sx={fabStyle} onClick={onClickAdd}>
                <AddIcon />
            </Fab>
        </div>
    );
}


