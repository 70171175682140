import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Alert, Button, Container } from "@mui/material";
import DashboardChart from "./DashboardChart";
import NextSessions from "./NextSessions";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { PAYMENT_STATISTICS } from "~/Queries/Payment/Payment.query";
import TodaySession from "./TodaySessions";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

function Earnings({
  title,
  amount,
  date,
  dateSeparator = "in",
  amountColor,
  goToPayout = false
}: {
  title?: string;
  amount: number;
  date: string;
  dateSeparator?: string;
  amountColor?: string;
  goToPayout?: boolean;
}) {
  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {title}&nbsp;
      </Typography>
      <Typography component="p" variant="h4" color={amountColor || "text.primary"}>
        ${amount}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {dateSeparator} {date}
      </Typography>
      {goToPayout && <Button size="small" variant={"contained"} href="payout-requests/add">Request Payout</Button>}
    </React.Fragment>
  );
}

export default function Dashboard() {
  const { data, error, loading } = useQuery(PAYMENT_STATISTICS);
  if (error) {
    return <Alert severity="error">{error.message ? error.message : 'Oops! An error has ocurred...Try again later!'}</Alert>

  }
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h3">Historical Earnings</Typography>
        </Grid>
        {/* Chart */}
        <Grid item xs={12} md={2} lg={2}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 140,
            }}
          >
            <Earnings
              amount={!loading ? data.paymentStatistics.earnings_past_year : 0}
              date="last 12 months"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 140,
            }}
          >
            <Earnings
              amount={
                !loading ? data.paymentStatistics.earnings_past_30_days : 0
              }
              date="last 30 days"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 140,
            }}
          >
            <Earnings
              amount={
                !loading ? data.paymentStatistics.earnings_past_7_days : 0
              }
              date="last 7 days"
            />
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={2} lg={2}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 140,
            }}
          >
            <Earnings
              title="Current Balance"
              amount={!loading ? data.paymentStatistics.earnings_today : 0}
              dateSeparator="as of"
              date={moment().format("MM-DD-YYYY")}
              goToPayout={true}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 140,
            }}
          >
            <Earnings
              title="Expected Earnings"
              amount={
                !loading ? data.paymentStatistics.earnings_next_30_days : 0
              }
              date={"the next 30 days"}
              amountColor="text.secondary"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 140,
            }}
          >
            <Earnings
              amount={
                !loading ? data.paymentStatistics.earnings_next_3_months : 0
              }
              date={"the next 3 months"}
              amountColor="text.secondary"
            />
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <TodaySession />
            <NextSessions />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
