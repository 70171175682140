
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Form from "~/Components/Form";
import { UserInputModel } from "~/Models/User.model";
import { GAMER_MODIFY } from "~/Queries/Gamer/Gamer.query";


class ProfileBankAccountFormModel extends UserInputModel {

}

class ProfileBankAccountFormInterface {
    data?: UserInputModel
}

export default function ProfileBankAccountForm(props: ProfileBankAccountFormInterface) {

    return (
        <Form model={UserInputModel} createMutation={GAMER_MODIFY} updateMutation={GAMER_MODIFY} data={props.data}>
            {({ errors, handleBlur, setFieldValue, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <Grid container spacing={2}>
                    {/* Account Holder Name */}
                    <Grid item md={12} xs={12} >
                        <FormControl fullWidth margin="dense">
                            <TextField
                                fullWidth
                                label="Account Holder Name"
                                name="account_holder_name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.account_holder_name}
                            />
                        </FormControl>
                    </Grid>
                    {/* Account holder type */}
                    <Grid item md={12} xs={12} >
                        <FormControl fullWidth margin="dense">
                            <InputLabel id="account-type-label">Account holder type</InputLabel>
                            <Select
                                name="account_holder_type"
                                value={values.account_holder_type}
                                label="Account holder type"
                                onBlur={handleBlur}
                                onChange={handleChange}
                            >
                                <MenuItem value={"individual"}>Individual</MenuItem>
                                <MenuItem value={"company"}>Company</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {/*  Account type */}
                    <Grid item md={12} xs={12} >
                        <FormControl fullWidth margin="dense">
                            <InputLabel id="account-type-label">Account type</InputLabel>
                            <Select
                                id="account-type"
                                labelId="account-type-label"
                                name="account_type"
                                value={values.account_type}
                                label="Account type"
                                onBlur={handleBlur}
                                onChange={handleChange}
                            >
                                <MenuItem value={"checking"}>Checking</MenuItem>
                                <MenuItem value={"saving"}>Saving</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* Bank Name */}
                    <Grid item md={12} xs={12} >
                        <FormControl fullWidth margin="dense">
                            <TextField
                                fullWidth
                                label="Bank Name"
                                name="bank_name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.bank_name}
                            />
                        </FormControl>
                    </Grid>
                    {/* Account Routing number */}
                    <Grid item md={12} xs={12} >
                        <FormControl fullWidth margin="dense">
                            <TextField
                                fullWidth
                                type={'number'}
                                label="Account Routing number"
                                name="account_routing_number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.account_routing_number}
                            />
                        </FormControl>
                    </Grid>
                    {/* Account Number */}
                    <Grid item md={12} xs={12} >
                        <FormControl fullWidth margin="dense">
                            <TextField
                                fullWidth
                                type={'number'}
                                label="Account number"
                                name="account_number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.account_number}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

            )
            }
        </Form >
    )
}