import gql from 'graphql-tag';

export const SESSION_FIELDS = gql`
  fragment SessionFields on Session {
    _id
    user_id
    gamer_id
    in_game_username
    start_date
    end_date
    user{
      name
      first_name
      last_name
      avatar_sku
      images {
          original
          sm
          md
          lg
          grayscale_sm
          grayscale_md
          grayscale_lg
      }
    }
    game{
      name
      images {
          original
          sm
          md
          lg
      }
    }
    payment{
      amount
      to_user_amount
      decimal_amount
      decimal_to_user_amount
    }
  }
`;
