import gql from 'graphql-tag';
import { GAME_FIELDS } from '~/Queries/Games/Game.fragment';
import { PLATFORM_FIELDS } from '../Platform/Platform.fragment';


export const GAME_GAMER_FIELDS = gql`
   ${GAME_FIELDS}
   ${PLATFORM_FIELDS}
  fragment GameGamerFields on GameGamer {
    _id
    user_id
    game_id
    active
    game {
      ...GameFields
    }
    platform{
        ...PlatformFields
    }
  }
`;
