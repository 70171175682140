import gql from 'graphql-tag';

export const REPORTED_ISSUE_FIELDS = gql`
  fragment ReportedIssueFields on ReportedIssue {
    _id
    issue_id
    user_id
    description
    active
    created_at
    updated_at
  }
`;
