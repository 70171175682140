import React, { useState } from 'react';
import { Alert, Box } from '@mui/material';
//tabs
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ProfileForm from '~/Components/Profile/ProfileForm';

import { CircularProgress } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GAMER_DATA } from '~/Queries/Gamer/Gamer.query';
import ProfileBankAccountForm from '~/Components/Profile/ProfileBankAccountForm';
import ProfilePricing from '~/Components/Profile/ProfilePricing';






export default function ProfilePage() {
    const [tabValue, setTabValue] = useState('1');
    const { data, loading, error } = useQuery(GAMER_DATA);


    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const ProfileFormTab = loading ? <CircularProgress /> : <ProfileForm data={data.gamerData} />
    const ProfileBankAccountFormTab = loading ? <CircularProgress /> : <ProfileBankAccountForm data={data.gamerData} />
    const ProfilePricingTab = loading ? <CircularProgress /> : <ProfilePricing data={data.gamerData} />

    
    if (error) {
        return <Alert severity="error">{error.message ? error.message : 'Oops! An error has ocurred...Try again later!'}</Alert>

    }
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                            <Tab label="Basic information" value="1" />
                            <Tab label="Bank Account information" value="2" />
                            <Tab label="Pricing & Split" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        {ProfileFormTab}
                    </TabPanel>
                    <TabPanel value="2">
                        {ProfileBankAccountFormTab}
                    </TabPanel>
                    <TabPanel value="3">
                        {ProfilePricingTab}
                    </TabPanel>

                </TabContext>
            </Box>
        </Box>
    )
}