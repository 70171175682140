import React,  {  useState } from "react";
import { Grid,  Avatar,  Tooltip } from "@mui/material"
import { Image } from "~/Models/Image.model";


interface ImagePreviewProps {
    images: Image[];
}

export default function ImagePreview({ images }: ImagePreviewProps) {
    const [previewFileData,] = useState<string>(images && images.length > 0 ? images[0].original as string : "");
    

    return (
        <>
            {
                previewFileData ? (
                    <>
                        <Grid item md={12} xs={12} sx={{ justifyContent: 'center', display: 'flex'}}>
                            {previewFileData &&
                                
                                    <Avatar src={previewFileData} variant="square" sx={{ width: 100, height: 100 }}  />
                            }
                        </Grid>

                    </>
                ) :
                    <>
                        <Grid item md={12} xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
                            <Tooltip title={"No image"} arrow placement="right">
                                <Avatar variant="square" sx={{ width: 100, height: 100 }}>No image</Avatar>
                            </Tooltip>
                        </Grid>

                    </>
            }

        </>
    )

}