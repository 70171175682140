import { Alert, Box, FormControl, Paper, TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';

import Form from "~/Components/Form";
import { GAMER_USER_ADD, GAMER_USER_UPDATE } from "~/Queries/GameGamer/GameGamer.query";
import { GAME_LIST } from "~/Queries/Games/Game.query";
import { useQuery } from "@apollo/client";


class GameInputModel {
    game_id!: string;

}

const defaultData: GameInputModel = {
    game_id: "",
}


interface GameFormInterface {
    id?: string | null;
    data?: any;
    onSuccess: () => void;
}

const CustomPaper = (props: any) => {
    return <Paper elevation={8} {...props} />;
};

export default function GameForm(props: GameFormInterface) {

    const { data, loading, error } = useQuery(GAME_LIST);

    // console.log({ data })
    if (error) {
        return <Alert severity="error">{error.message ? error.message : 'Oops! An error has ocurred...Try again later!'}</Alert>

    }
    return (
        <Form onSuccess={props.onSuccess} title="Add game" model={GameInputModel} createMutation={GAMER_USER_ADD} updateMutation={GAMER_USER_UPDATE} data={props.data || defaultData} >
            {({ errors, handleBlur, setFieldValue, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <>
                    <FormControl fullWidth margin="dense">
                        {<Autocomplete
                            id="game_id"
                            getOptionLabel={(item: any) => item.name}
                            PaperComponent={CustomPaper}
                            onChange={(e, item: any) => {
                                // console.log(item);
                                if (item) {
                                    setFieldValue("game_id", item._id);
                                } else {
                                    setFieldValue("game_id", item);
                                }


                            }}
                            onBlur={handleBlur}
                            disablePortal
                            options={loading ? [] : data.gameList}
                            renderInput={(params) => <TextField {...params} label="Games" />}

                            renderOption={(props, item: any) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img
                                        loading="lazy"
                                        width="40"
                                        src={item.images ? item.images[0].sm : null}
                                        alt=""
                                    />
                                    {item.name}
                                </Box>
                            )}

                        />}

                    </FormControl>

                </>

            )
            }
        </Form >
    )
}