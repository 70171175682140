import gql from 'graphql-tag';

export const PAYOUT_REQUEST_FIELDS = gql`
  fragment PayoutRequestFields on PayoutRequest {
    _id
    amount
    status
    decimal_amount
    created_at
    reason
  }
`;
