import React from 'react';

import GameForm from "~/Components/GameForm";


export default function GameAddPage() {

    return (
        <GameForm onSuccess={() => null} />
    )
}