import React, { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

import { UserInputModel } from "~/Models/User.model";
import { Image } from "~/Models/Image.model";
import { useSnackbar } from "notistack";
import { GAMER_MODIFY } from "~/Queries/Gamer/Gamer.query";
import ImagePreview from "../ImagePreview";

const OmitProps = <T, K extends keyof T>(
  Class: new () => T,
  keys: K[]
): new () => Omit<T, typeof keys[number]> => Class;

class ProfileFormModel extends UserInputModel {
  constructor() {
    super();
    delete this.price_off_stream;
    delete this.price_on_stream;
    delete this.profit_percent_on_stream;
    delete this.profit_percent_off_stream;
  }
}

class ProfileFormInterface {
  data?: UserInputModel & {
    images: Image[];
    covers: Image[];
    file?: File | null;
    cover?: File | null;
  };
}

export default function ProfileForm({
  data = {
    name: "",
    first_name: "",
    last_name: "",
    image: "",
    active: true,
    images: [],
    covers: [],
    created_at: new Date(),
    updated_at: new Date(),
    company_name: "",
    password: "",
    url: "",
    email: "",
    type: ["user"],
    about_me: "",
    address: "",
    display_name_key: "fullname",
    phone: "",
    sex: "",
    facebook_url: "",
    twitch_url: "",
    instagram_url: "",
    price_off_stream: 0,
    price_on_stream: 0,
    profit_percent_off_stream: 0,
    profit_percent_on_stream: 0,
  },
}: ProfileFormInterface) {
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(255).required("Name is empty"),
    first_name: Yup.string().max(255).required("First Name is empty"),
    last_name: Yup.string().max(255).required("Last Name is empty"),
  });

  const client = useApolloClient();

  // const changeProfilePhoto = useCallback(async (file: File) => {
  //     const response = await client.mutate({
  //         mutation: GAMER_UPLOAD_PROFILE_PHOTO,
  //         variables: { image: file }
  //     });

  //     if (response.data.gamerUploadProfilePhoto) {
  //         enqueueSnackbar('Profile photo updated', { variant: 'success' });
  //     } else {
  //         enqueueSnackbar('Error submiting the profile photo. Please try again.', { variant: 'error' });
  //     }

  // }, [])

  // const changeCoverPhoto = useCallback(async (file: File) => {
  //     const response = await client.mutate({
  //         mutation: GAMER_UPLOAD_COVER,
  //         variables: { image: file }
  //     });

  //     if (response.data.gamerUploadCover) {
  //         enqueueSnackbar('Profile cover updated', { variant: 'success' });
  //     } else {
  //         enqueueSnackbar('Error submiting the profile cover. Please try again.', { variant: 'error' });
  //     }

  // }, [])

  const onSubmit = useCallback(
    async (values: any, { setErrors, setStatus, setSubmitting }: any) => {
      try {
        let result: ProfileFormModel = new ProfileFormModel();

        result = Object.keys(result).reduce((result: any, fieldName) => {
          const value = values[fieldName];
          if (value != null) {
            result[fieldName] = values[fieldName];
          }

          return result;
        }, result);

        let saved = false;

        if (result.password && result.password.length === 0) {
          delete result.password;
        }
        const response = await client.mutate({
          mutation: GAMER_MODIFY,
          variables: { data: result },
        });
        saved = response.data.gamerModify;

        if (saved) {
          await setStatus({ success: true });
          await setSubmitting(false);
          enqueueSnackbar("Profile updated", { variant: "success" });
        } else {
          setStatus({ success: false });
          setErrors({ submit: "Error submiting the item. Please try again." });
          setSubmitting(false);
          enqueueSnackbar("Error submiting the item. Please try again.", {
            variant: "error",
          });
        }
      } catch (err: any) {
        console.log({ err });
        setStatus({ success: false });
        setErrors({ submit: err.message });
        enqueueSnackbar(err.message, { variant: "error" });
        setSubmitting(false);
      }

      setStatus({ success: false });
      setSubmitting(false);
    },
    []
  );

  return (
    <Box>
      <Formik
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          setFieldValue,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <ImagePreview images={values.images} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <ImagePreview images={values.covers} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl variant="standard" fullWidth margin="dense">
                    <TextField
                      label="Username"
                      disabled={true}
                      value={values.name}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error>{errors.name}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl variant="standard" fullWidth margin="dense">
                    <TextField
                      label="Email"
                      disabled={true}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText error>{errors.email}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {/* First Name */}
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      label="First name"
                      name="first_name"
                      value={values.first_name}
                      fullWidth
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.first_name && errors.first_name && (
                      <FormHelperText error>{errors.first_name}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {/* Last Name */}
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      label="Last name"
                      name="last_name"
                      disabled={true}
                      value={values.last_name}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.last_name && errors.last_name && (
                      <FormHelperText error>{errors.last_name}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {/* Display Name */}
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth={true} margin="dense">
                    <InputLabel id="display-name-label">
                      Display Name
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="display-name-label"
                      id="display-name-select"
                      name="display_name_key"
                      value={values.display_name_key}
                      label="Display name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <MenuItem value={"fullname"}>
                        First name + Last name
                      </MenuItem>
                      <MenuItem value={"username"}>Username</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/* Password */}
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      label={"Password (Write to change the current one)"}
                      name="password"
                      value={values.password}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                {/* About Me */}
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      label={"About me"}
                      name="about_me"
                      value={values.about_me}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                {/* Facebook */}
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      label={"Facebook URL"}
                      name="facebook_url"
                      value={values.facebook_url}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                {/* Twitch */}
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      label={"Twitch URL"}
                      name="twitch_url"
                      value={values.twitch_url}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                {/* Instagram */}
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      label={"Instagram URL"}
                      name="instagram_url"
                      value={values.instagram_url}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Button
                disableElevation
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                Update profile
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}
