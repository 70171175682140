/* eslint-disable sort-keys */
import { atom } from 'recoil';

import { User } from '~/Models/User.model';
import localStorageEffect from '~/Recoil/Effects/LocalStorageEffect';

// Auth User
export const authUserAtom = atom<User | null>({
  key: 'Auth.User',
  default: null,
  effects: [
    localStorageEffect('@user')
  ]
});
// Auth User Token
export const authUserTokenAtom = atom<string | null>({
  key: 'Auth.Token',
  default: null,
  effects: [
    localStorageEffect('@token')
  ]
});
