import FullCalendar, { EventApi, EventContentArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { AVAILABLE_DATE_CALENDAR } from "~/Queries/AvailableDate/AvailableDate.query";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import React, { useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
} from "@mui/material";
import SessionView from "~/Components/SessionView";
import AvailableDateView from "~/Components/AvailableDateView";

function pad(str: number | undefined) {
  return str ? String(str).padStart(2, "0") : "00";
}

interface DialogData {
  open: boolean;
  isChatEnabled?: boolean;
  event?: EventApi | null;
}

const routes: { [key: string]: string } = {
  Session: "/sessions/view/",
  AvailableDate: "/sessions/available-dates/edit/",
};

export default function SessionCalendarPage() {
  const { data, loading, error } = useQuery(AVAILABLE_DATE_CALENDAR);

  const navigate = useNavigate();
  const theme = useTheme();
  const [showDialog, setShowDialog] = React.useState<DialogData>({
    open: false,
  });

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const closeDialogCallback = useCallback(() => {
    setShowDialog({ open: false, event: null });
  }, []);
  const viewDialogCallback = useCallback(() => {
    const event = showDialog.event;

    setShowDialog({ open: false, event: null });
    if (event) {
      const { _id, model } = event._def.extendedProps;

      if (_id && routes[model]) {
        navigate(routes[model] + _id);
      }
    }
  }, [navigate, showDialog]);

  const renderDialogContent = () => {
    if (showDialog.event && showDialog.event._def) {
      const model = showDialog.event._def.extendedProps.model;
      if (model === "Session") {
        return (
          <SessionView item={showDialog.event._def.extendedProps.modelData} />
        );
      }
      if (model === "AvailableDate") {
        return (
          <AvailableDateView
            item={showDialog.event._def.extendedProps.modelData}
          />
        );
      }
    }

    return null;
  };

  const renderDialogTitle = () => {
    if (showDialog.event && showDialog.event._def) {
      const model = showDialog.event._def.extendedProps.model;
      if (model === "Session") {
        return "Session";
      }
      if (model === "AvailableDate") {
        return showDialog.event._def.extendedProps.modelData.available
          ? "Available Date"
          : "Blocked Date";
      }
    }

    return "Item";
  };

  if (error) {
    return (
      <Alert severity="error">
        {error.message
          ? error.message
          : "Oops! An error has ocurred...Try again later!"}
      </Alert>
    );
  }
  return (
    <>
      <FullCalendar
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        initialView="timeGridWeek"
        selectable={true}
        editable={true}
        locale="en"
        events={loading ? [] : data.availableDateCalendar}
        eventContent={function (eventInfo: EventContentArg) {
          if (eventInfo.view.type == "dayGridMonth") {
            //eventInfo.timeText = pad(hours12(eventInfo.event.start ? eventInfo.event.start : new Date)) + ":" + pad(eventInfo.event.start?.getMinutes()) + " - " + pad(eventInfo.event.end?.getHours()) + ":" + pad(eventInfo.event.end?.getMinutes())
            eventInfo.timeText =
              moment(eventInfo.event.start).format("hh:mmA") +
              " - " +
              moment(eventInfo.event.end).format("hh:mmA");
          }
        }}
        eventClick={function ({ event }: any) {
          const { _id, model }: { _id: string; model: string } =
            event._def.extendedProps;
          if (!_id) {
            return false;
          }

          if (_id && routes[model]) {
            //const url = routes[model] + _id;
            //navigate(url);
            //console.log('event', event)
            let isChatEnabled = false;

            if (model === "Session") {
              const dateX = moment(
                event._def.extendedProps.modelData.session_end_date
              );
              const hourDiff = moment(dateX).diff(moment(), "hours");

              isChatEnabled = hourDiff <= 1 && hourDiff >= -1;
            }
            setShowDialog({ open: true, event, isChatEnabled });
          }
        }}
      />
      <Dialog
        fullScreen={fullScreen}
        open={showDialog.open}
        onClose={closeDialogCallback}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {renderDialogTitle()}
        </DialogTitle>
        <DialogContent>{renderDialogContent()}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeDialogCallback}>
            Close
          </Button>
          <Button onClick={viewDialogCallback} autoFocus>
            View
          </Button>
          {showDialog.event &&
          showDialog.event._def.extendedProps.model === "Session" &&
          showDialog.isChatEnabled ? (
            <Button
              autoFocus
              onClick={() =>
                navigate(
                  "/sessions/chat/" + showDialog.event?._def.extendedProps._id
                )
              }
            >
              chat
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
}
