import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';

import { DataGrid, GridToolbar, GridActionsCellItem, GridColumns } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


import { REPORTED_ISSUE_LIST } from '~/Queries/ReportIssue/ReportedIssue.query';





const headCells: GridColumns = [
    { field: '_id', headerName: 'Id', hide: true },
    { field: 'active', headerName: 'Active' },
    { field: 'description', headerName: 'Description', width: 250 },
    { field: 'created_at', headerName: 'Created', width: 150 },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        getActions: () => [
            <GridActionsCellItem icon={<EditIcon />} label="Edit" />,
            <GridActionsCellItem icon={<DeleteIcon />} label="Delete" />,
        ],
    }
];



export default function ReportedIssuesPage() {
    const { loading, error, data } = useQuery(REPORTED_ISSUE_LIST);

    if (loading) {
        return <CircularProgress />
    }

    return (
        <div style={{ height: '70vh', width: '100%' }}>

            <DataGrid

                getRowId={(row) => row._id}
                rows={data.reportedIssueList}
                columns={
                    headCells
                }
                components={{
                    Toolbar: GridToolbar,
                }}
            ></DataGrid>
        </div>
    );
}


