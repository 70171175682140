import { useQuery } from "@apollo/client";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";

import SessionView from "~/Components/SessionView";
import { SESSION_FIND_BY_ID } from "~/Queries/Sessions/Session.query";

export default function SessionViewPage() {
  const { id } = useParams();

  const { data, loading, error } = useQuery(SESSION_FIND_BY_ID, {
    variables: { id },
  });
  if (error) {
    return (
      <Alert severity="error">
        {error.message
          ? error.message
          : "Oops! An error has ocurred...Try again later!"}
      </Alert>
    );
  }
  let isChatEnabled = false;
  if (!loading) {
    if (data.sessionFindById && data.sessionFindById.end_date) {
      const dateX = moment(data.sessionFindById.end_date);
      const hourDiff = moment(dateX).diff(moment(), "hours");
      isChatEnabled = hourDiff <= 1 && hourDiff >= -1;
    }
  }
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" className="header-message">
            Session
          </Typography>
        </Grid>

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <SessionView item={data.sessionFindById} />

            <Grid xs={12}>
              {isChatEnabled && (
                <Button variant="contained" href={"/sessions/chat/" + id}>
                  Chat
                </Button>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
