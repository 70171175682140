import gql from 'graphql-tag';

export const USER_FIELDS = gql`
  fragment UserFields on User {
    _id
    name
    email
    first_name
    last_name
    phone
    sex
    url
    company_name
    image
    about_me
    type
    review
    active
    created_at
    updated_at
    images {
      original
      sm
      md
      lg
    }
    covers {
      original
      cover
    }
    #favorites
    favorites_gamers_list
  }
`;
