import gql from 'graphql-tag';

import { PLATFORM_FIELDS } from './Platform.fragment';

//#region Queries
export const PLATFORM_LIST = gql`
  ${PLATFORM_FIELDS}
  query platformList {
    platformList {
      ...PlatformFields
    }
  }
`;
export const PLATFORM_FIND_BY_ID = gql`
  ${PLATFORM_FIELDS}
  query platformFindById($id: String!) {
    platformFindById(id: $id) {
      ...PlatformFields
    }
  }
`;
//#endregion Queries

//#region Mutations

export const PLATFORM_ADD = gql`
  ${PLATFORM_FIELDS}
  mutation platformAdd($data:PlatformInput !) {
    platformAdd(data:$data) {
      ...PlatformFields
    }
  }
`;
export const PLATFORM_UPDATE = gql`
  ${PLATFORM_FIELDS}
  mutation platformUpdate($id: String!, $data:PlatformInput !) {
    platformUpdate(id: $id,data:$data) {
      ...PlatformFields
    }
  }
`;

export const PLATFORM_DELETE = gql`
  ${PLATFORM_FIELDS}
  mutation platformDelete($id: String!) {
    platformDelete(id: $id) {
      ...PlatformFields
    }
  }
`;
//#endregion Mutations
