export default class PricingUtility {
    static calculateProfitStripe(price: number): number {
        const calculate = (price * 1.085 + 0.3) / (1 - 0.029);
        return Math.round(calculate * 100) / 100
    }
    static round(value: number): number {
        return Math.round(value * 100) / 100
    }

}
