import gql from 'graphql-tag';

export const AVAILABLE_DATE_FIELDS = gql`
  fragment AvailableDateFields on AvailableDate {
    _id
    session_type
    available
    user_id
    days
    start_date
    end_date
    start_hour
    start_minute
    end_hour
    end_minute
    timezone
    created_at
    updated_at
  }
`;
