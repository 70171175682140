import gql from 'graphql-tag';

import { AVAILABLE_DATE_FIELDS } from './AvailableDate.fragment';
//#region Queries
export const AVAILABLE_DATE_FIND_BY_ID = gql`
  ${AVAILABLE_DATE_FIELDS}
  query availableDateFindById($id: String!) {
    availableDateFindById(id: $id) {
      ...AvailableDateFields
    }
  }
`;


export const AVAILABLE_DATE_CALENDAR = gql`
${AVAILABLE_DATE_FIELDS}
  query availableDateCalendar{
    availableDateCalendar{
      _id
      title
      color
      allDay
      available
      start
      end
      startRecur
      endRecur
      startTime
      endTime
      daysOfWeek
      user_id
      session_type
      model
      timezone
      timeZone
      modelData{
        ... on Session {
            user_id
            gamer_id
            start_date
            session_end_date: end_date
            user{
              first_name
              last_name
              name
              avatar_sku
            }
            game{
              name
              images {
                original
                sm
                md
                lg
              }
            }
        }

        ... on AvailableDate {
          ...AvailableDateFields
        }
      }

    }
  }
`;

export const AVAILABLE_DATE_FIND_BY_USER_ID = gql`
  ${AVAILABLE_DATE_FIELDS}
  query availableDateFindByUserId {
    availableDateFindByUserId {
      ...AvailableDateFields
      
    }
  }
`;

//#endregion Queries

//#region Mutations
export const AVAILABLE_DATE_ADD = gql`
  ${AVAILABLE_DATE_FIELDS}
  mutation availableDateAdd($data: AvailableDateInput!) {
    availableDateAdd(data: $data) {
      ...AvailableDateFields
    }
  }
`;
export const AVAILABLE_DATE_UPDATE = gql`
  ${AVAILABLE_DATE_FIELDS}
  mutation availableDateUpdate($id:String!, $data: AvailableDateInput!) {
    availableDateUpdate(id: $id, data: $data) {
      ...AvailableDateFields
    }
  }
`;
export const AVAILABLE_DATE_DELETE = gql`
  ${AVAILABLE_DATE_FIELDS}
  mutation availableDateDelete($id: String!) {
    availableDateDelete(id: $id) {
      ...AvailableDateFields
    }
  }
`;
//#endregion Mutations
