import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { UserInputModel } from "~/Models/User.model";
import PricingUtility from "~/Utilities/Pricing/Pricing.utility";

class ProfilePricingProps {
  data?: UserInputModel;
}

export default function ProfilePricing(props: ProfilePricingProps) {
  const { data } = props;
  if (!data) {
    return null;
  }
  const earnOnStream = data.price_on_stream
    ? data.price_on_stream *
      (data.profit_percent_on_stream ? data.profit_percent_on_stream / 100 : 0)
    : 0;
  const earnOffStream = data.price_off_stream
    ? data.price_off_stream *
      (data.profit_percent_off_stream
        ? data.profit_percent_off_stream / 100
        : 0)
    : 0;
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                On Stream
              </Typography>
              <Typography variant="body2">
                {`Price $${data?.price_on_stream} - Split ${
                  data?.profit_percent_on_stream
                }% you will earn $${PricingUtility.round(
                  earnOnStream
                )} per session`}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Off Stream
              </Typography>
              <Typography variant="body2">
                {`Price $${data?.price_off_stream} - Split ${
                  data?.profit_percent_off_stream
                }% you will earn $${PricingUtility.round(
                  earnOffStream
                )} per session`}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
