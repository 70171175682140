import React, { useCallback, useState } from 'react';
import {
  Alert,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import Form from "~/Components/Form";

import { useQuery } from "@apollo/client";
import { PAYOUT_REQUEST_ADD } from "~/Queries/PayoutRequest/PayoutRequest.query";


import NumberFormat from "react-number-format";
import { PAYMENT_STATISTICS } from "~/Queries/Payment/Payment.query";

class PayoutRequestInputModel {
  amount!: number;
}

const defaultData: PayoutRequestInputModel = {
  amount: 0,
};

interface PayoutRequesInterface {
  id?: string | null;
  data?: any;
}

export default function PayoutRequestForm(props: PayoutRequesInterface) {
  const [hasValidData, setValidData] = useState<Boolean>(false);
  const { data, error, loading } = useQuery(PAYMENT_STATISTICS);

  //const { data, loading, error } = useQuery(GAME_LIST);
  //let items = <CircularProgress />;
  /*if (!loading && !error && data) {

        items = data.gameList.map((item: any) => (
            <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
        ))
    }*/


  const onAmountChange = useCallback(
    (values, setFieldValue) => {
      const maxPayout = data.paymentStatistics.earnings_today;
      if (values.value > maxPayout) {
        values.value = maxPayout;
      }
      setFieldValue("amount", values.value);
      setValidData(values.value > 0);
    },
    [data]
  );

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error.message ? error.message : 'Oops! An error has ocurred...Try again later!'}</Alert>

  }


  return (
    <Form
      title={"Request Payout"}
      hasValidData={hasValidData}
      addTitle={"Request Payout"}
      beforeSubmit={(values: any) => {
        values.amount = values.amount * 100;
        return values;
      }}
      model={PayoutRequestInputModel}
      createMutation={PAYOUT_REQUEST_ADD}
      updateMutation={PAYOUT_REQUEST_ADD}
      backLink="/payout-requests"
      data={props.data || defaultData}
    >
      {({
        errors,
        handleBlur,
        setFieldValue,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <>
          <Typography variant="h4" mt={2}>
            Current Balance:{" "}
            <strong>
              <span style={{ color: "#FF11A3" }}>$
                {data.paymentStatistics.earnings_today}
              </span>
            </strong>
          </Typography>
          <FormControl fullWidth margin='dense'>
            <NumberFormat
              customInput={TextField}
              name="amount"
              value={values.amount}
              onBlur={handleBlur}
              onValueChange={(values: any) =>
                onAmountChange(values, setFieldValue)
              }
              prefix={"$ "}
              decimalScale={2}
            />
          </FormControl>
        </>
      )}
    </Form>
  );
}
