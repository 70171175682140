/* eslint-disable sort-keys */
import { Avatar } from '~/Models/Avatar.model';

import avatar1 from '../assets/images/avatars/a1.png';
import avatar2 from '../assets/images/avatars/a2.png';
import avatar3 from '../assets/images/avatars/a3.png';
import avatar4 from '../assets/images/avatars/a4.png';
import avatar5 from '../assets/images/avatars/a5.png';
import avatar6 from '../assets/images/avatars/a6.png';
import avatar7 from '../assets/images/avatars/a7.png';
import avatar8 from '../assets/images/avatars/a8.png';


export const AVATARS: Array<Avatar> = [
  {
    sku: 'avatar_1',
    image: avatar1
  },
  {
    sku: 'avatar_2',
    image: avatar2
  },
  {
    sku: 'avatar_3',
    image: avatar3
  },
  {
    sku: 'avatar_4',
    image: avatar4
  },
  {
    sku: 'avatar_5',
    image: avatar5
  },
  {
    sku: 'avatar_6',
    image: avatar6
  },
  {
    sku: 'avatar_7',
    image: avatar7
  },
  {
    sku: 'avatar_8',
    image: avatar8
  }
];
